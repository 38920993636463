import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { IoChevronForwardOutline } from "react-icons/io5";
import './Breadcrumb.css'
import { useSelector } from 'react-redux';

const Breadcrumb = ({ show, isTop }) => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const purchasePackage = location.pathname.split('/')[4]

    const utilReducers = useSelector((state) => state.utilReducers);

    // Create breadcrumb items
    const breadcrumbs = pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;

        console.log("routeTo", routeTo);
        console.log("purchasePackage :", purchasePackage);


        return index < 3 && (
            <div key={index} className="breadcrumb-item poppins-medium text-[1.6rem] flex justify-start items-center">
                <Link
                    to={routeTo === "/fit-center" ? "fit-center/fit-prime" : routeTo}
                >
                    {pathnames.length > 1
                        &&
                        isLast ?
                        name !== "gym" ?
                            null
                            :
                            utilReducers?.plan?.totalDuration + " m Offers"
                        : name == "gym" ?
                            null
                            : name == "our-story" ?
                                "About us"
                                : name == "premium" ?
                                    "Fit Prime" : name == "champion" ?
                                        "Fit Champ"
                                        : (purchasePackage == "init-pay" || purchasePackage == "pay-status") && index == 2 ?
                                            "payment"
                                            :
                                            name
                    }
                </Link>
                {!isLast && <IoChevronForwardOutline />}
            </div>
        );
    });

    console.log("BreadcrumbBreadcrumb pathnames :", pathnames);


    return show && (
        <nav className={`bg-transparent px-[4rem] fixed ${isTop ? 'top-[8.4rem]' : 'top-[12.4rem]'} left-0 right-0 z-[97]`} aria-label="breadcrumb">
            <div className="container-lg flex justify-start items-center bg-transparent text-white">
                <div className="breadcrumb-item poppins-medium text-[1.6rem] flex justify-start items-center">
                    <Link to="/">Home</Link>
                    <IoChevronForwardOutline />
                </div>
                {breadcrumbs}
            </div>
        </nav>
    );
};

export default Breadcrumb;
