import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IoCheckmark } from 'react-icons/io5'
import { useToast } from '../../../hooks/useToast';
import { DownloadAppLandingImg } from '../../../static/constants/imgConstants';
import { OrderCreate, OrderCreateReset } from '../../../store/actions/orderManagementActions/orderCreateActions';
import { makePayment, makePaymentReset } from '../../../store/actions/paymentGatewayActions/makePaymentActions';
import { checkPaymentStatus, checkPaymentStatusReset } from '../../../store/actions/paymentGatewayActions/checkPaymentStatusActions';
import "./PayStepForm.css"
import { OrderTransactionStatus, OrderTransactionStatusReset } from '../../../store/actions/orderManagementActions/orderTransactionStatusActions';
import moment from 'moment';
import { Oval } from 'react-loader-spinner';
import { logout, setUpgradeUnable } from '../../../store/actions/utilsActions/utilsActions';
import { refreshToken, refreshTokenReset } from '../../../store/actions/authActions/refreshTokenActions';

const PayStepForm = () => {

    const Toast = useToast();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { package_type, package_id, pay_step } = useParams();

    const orderCreateReducer = useSelector((state) => state.orderCreateReducers?.data);
    const utilReducers = useSelector((state) => state.utilReducers);
    const orderCreateReducerStatus = useSelector((state) => state.orderCreateReducers?.status);
    const orderCreateReducerLoading = useSelector((state) => state.orderCreateReducers?.loading);
    const makePaymentReducer = useSelector((state) => state.makePaymentReducer?.data);
    const makePaymentReducerStatus = useSelector((state) => state.makePaymentReducer?.status);
    const makePaymentReducerLoading = useSelector((state) => state.makePaymentReducer?.loading);
    const checkPaymentStatusReducer = useSelector((state) => state.checkPaymentStatusReducer?.data);
    const checkPaymentStatusReducerStatus = useSelector((state) => state.checkPaymentStatusReducer?.status);
    const orderTransactionStatusReducerStatus = useSelector((state) => state.orderTransactionStatusReducer?.status);
    const orderTransactionStatusReducerLoading = useSelector((state) => state.orderTransactionStatusReducer?.loading);
    const orderTransactionStatusReducerData = useSelector((state) => state.orderTransactionStatusReducer?.data);
    const refreshTokenReducerStatus = useSelector((state) => state.refreshTokenReducer.status);
    const refreshTokenReducerLoading = useSelector((state) => state.refreshTokenReducer.loading);

    const [redirectUrl, setRedirectUrl] = useState('');
    const [offerApplied, setOfferApplied] = useState(false);
    const [termsAndCondition, setTermsAndCondition] = useState(false);
    const [coupon, setCoupon] = useState("")

    function getValueByName(properties, name) {
        const property = properties?.find(prop => prop.name === name);
        return property ? property?.value : null;
    }

    const getDateTime = () => {
        const date = new Date();
        const [hours, minutes, seconds] = "00:00:00".split(':').map(Number);
        date.setHours(hours, minutes, seconds); // Sets hours, minutes, and seconds
        const isoString = date.toISOString();
        return isoString;
    }

    const parsePoints = (htmlString) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        const paragraphs = Array.from(tempDiv.getElementsByTagName('p')).map(p => p.innerHTML);
        return paragraphs;
    }

    const parsedPoints = parsePoints(utilReducers?.plan?.description);

    const totalPriceAfterDiscount = utilReducers?.plan?.totalAmount - (utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.offerValue : 0);
    const totalTax = (totalPriceAfterDiscount * 18) / 100;
    const totalTaxWithoutDiscount = (utilReducers?.plan?.totalAmount * 18) / 100;
    const totalAmount = totalPriceAfterDiscount + totalTax;
    const totalAmountWithoutDiscount = utilReducers?.plan?.totalAmount + totalTaxWithoutDiscount;


    useEffect(() => {
        if (pay_step === "pay-status") {
            /* dispatch(checkPaymentStatus({
                PhonePeMerchantId: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_MERCHANTID')[0],
                PhonePeMerchantTransId: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_MERCHANT_TRANSID')[0],
                PhonePeTransCheckXVerify: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_TRANSCHECK_X_VERIFY')[0],
            })); */
            dispatch(OrderTransactionStatus(orderCreateReducer));
        }
    }, [pay_step]);

    useEffect(() => {
        console.log("orderCreateReducerStatus :", orderCreateReducer);
        if (orderCreateReducerStatus === 200) {
            console.log("orderCreateReducerStatus 2 :", orderCreateReducerStatus);
            dispatch(makePayment({
                PhonePeRequestJSONBase64: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_REQUESTJSONBASE64')[0],
                PhonePeHash: getValueByName(orderCreateReducer.extendedProperties, 'PHONEPE_HASH')[0],
            }));
            dispatch(OrderCreateReset());
        }
    }, [orderCreateReducerStatus]);

    useEffect(() => {
        if (makePaymentReducerStatus === 200) {
            dispatch(makePaymentReset());
            const redirect = makePaymentReducer.data.instrumentResponse.redirectInfo.url;
            setRedirectUrl(redirect);
            navigate(`/fit-center/${package_type}/${package_id}/payment`);
        } else if (makePaymentReducerStatus == 429) {
            Toast("Payment link is not working! Please try again later");
            dispatch(makePaymentReset());
        } else if (makePaymentReducerStatus) {
            Toast("Payment link is not working! Please try again later");
            dispatch(makePaymentReset());
        }
    }, [makePaymentReducerStatus]);

    /* useEffect(() => {
        if (checkPaymentStatusReducerStatus === 200) {
            Toast("Payment Successful!🎉")
            dispatch(checkPaymentStatusReset());
        } else if (checkPaymentStatusReducerStatus) {
            Toast("Payment Failed");
            dispatch(checkPaymentStatusReset());
        }

    }, [checkPaymentStatusReducerStatus]); */

    useEffect(() => {
        if (!redirectUrl) {
            navigate(`/fit-center/${package_type}/${package_id}/init-pay`)
        }
    }, [redirectUrl])

    useEffect(() => {
        if (orderTransactionStatusReducerStatus == 200) {
            Toast("Payment Successful!🎉", "success")
            dispatch(OrderTransactionStatusReset());
            navigate(`/fit-center/${package_type}/${package_id}/pay-status`);
        } else if (orderTransactionStatusReducerStatus) {
            Toast("Payment Failed", "error");
            dispatch(OrderTransactionStatusReset());
            navigate(`/fit-center/${package_type}/${package_id}/init-pay`)
        }
    }, [orderTransactionStatusReducerStatus])

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            // Handle your custom back button logic here, e.g.:
            navigate("/");
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate]);

    useEffect(() => {
        dispatch(refreshToken({
            refreshToken: utilReducers?.refreshToken,
            accessToken: utilReducers?.accessToken
        }))
    }, [])


    useEffect(() => {
        if (refreshTokenReducerStatus === 200) {
            dispatch(refreshTokenReset());
        } else if (refreshTokenReducerStatus) {
            Toast("Session expired! 😓", "error");
            dispatch(logout());
            navigate("/")
        }
    }, [refreshTokenReducerStatus]);


    console.log("orderTransactionStatusReducerData :", orderTransactionStatusReducerData);
    console.log("utilReducers?.plan.offers :", utilReducers?.plan?.offers);



    return (
        <div className="w-full px-[4rem]">
            <div className="container-lg flex flex-col lg:flex-row justify-between items-center lg:items-start pt-[9rem]">
                <div className="w-full lg:w-[50%] flex flex-col justify-start items-start lg:block">
                    <div className="" />
                    <div className="urbanist-semibold text-white text-[4.8rem] mt-[2rem]">
                        {utilReducers.planType == "fit-prime" ? "Premium Plan" : "Champion Plan"}
                    </div>
                    <div className="urbanist-semibold text-white text-[3.6rem] my-[1.5rem]">
                        {utilReducers?.plan?.totalDuration == "1" ? `${utilReducers?.plan?.totalDuration} Month Plan` : `${utilReducers?.plan?.totalDuration} Months Plan`}
                    </div>
                    <div className="">
                        {parsedPoints.map((point) => {
                            return (
                                <div className="flex justify-start items-center my-5">
                                    <IoCheckmark color='#FFF6F6' size={20} className='mr-4' />
                                    <div className="urbanist-medium text-[#FFF6F6] text-[1.6rem]">
                                        <span dangerouslySetInnerHTML={{ __html: point }} />
                                    </div>
                                </div>
                            )
                        })
                        }
                    </div>
                    {pay_step === "init-pay" ?
                        <div className="">
                            <div className="urbanist-semibold text-white text-[3.6rem] my-[3rem]">T&C</div>
                            <div className="flex items-center">
                                <input className='mx-2 mr-5' type="checkbox" value={termsAndCondition} onChange={() => setTermsAndCondition(!termsAndCondition)} />
                                <label
                                    for="vehicle1"
                                    className='urbanist-medium text-[#FFF6F6] text-[1.6rem]'
                                >
                                    Agree to All &nbsp;
                                    <span
                                        className="cursor-pointer underline text-[#0ff]"
                                        onClick={() => window.open("https://fit.zone/#/static/terms", '_blank').focus()}
                                    >
                                        Terms and Conditions
                                    </span>
                                </label>
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                <div className="w-full xl:w-[50%] mt-[2rem]">
                    <div className="flex justify-center items-center text-white w-[97%] mr-5">
                        <div className={`w-[14rem]`}>
                            <div className={`urbanist-bold ${pay_step === "init-pay" ? "bg-[#D2F6FF] text-black" : ""} text-[1.8rem] border border-white rounded-full flex justify-center items-center p-5`}>
                                1
                            </div>
                        </div>
                        <div className="w-full border-t border-white"></div>
                        <div className={`w-[14rem]`}>
                            <div className={`urbanist-bold ${pay_step === "payment" ? "bg-[#D2F6FF] text-black" : ""} text-[1.8rem] border border-white rounded-full flex justify-center items-center p-5`}>
                                2
                            </div>
                        </div>
                        <div className="w-full border-t border-white"></div>
                        <div className={`w-[14rem]`}>
                            <div className={`urbanist-bold ${pay_step === "pay-status" ? "bg-[#D2F6FF] text-black" : ""} text-[1.8rem] border border-white rounded-full flex justify-center items-center p-5`}>
                                3
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center urbanist-medium text-[1.6rem] text-center text-white my-3">
                        <div className="mx-4">Plan</div>
                        <div className="ml-8">Payment</div>
                        <div className="">Confirmation</div>
                    </div>
                    <div className="bg-[#293C52] px-[4rem] md:mx-[8rem] py-[3rem] rounded-[2rem] mt-[2.3rem] relative">
                        {!orderTransactionStatusReducerLoading ?
                            pay_step === "pay-status" ?
                                <div className="flex flex-col justify-center items-center my-[12rem]">
                                    <div className="text-[#EFF7FF] urbanist-semibold text-[6.4rem]">
                                        Congratulations!
                                    </div>
                                    <div className="text-[#0FAD4E] urbanist-semibold text-[2.4rem] my-[3rem] flex justify-between items-center">
                                        Payment received <IoCheckmark color='#0FAD4E' size={28} className='ml-10' />
                                    </div>
                                    <div className="questrial-regular text-[2.4rem] text-white">
                                        Subscription will be activated from {moment(orderTransactionStatusReducerData?.startdate).format("MMM Do YY")}
                                    </div>
                                </div>
                                : redirectUrl && pay_step === "payment" ?
                                    <div className="flex flex-col justify-center items-center">
                                        {window.open(redirectUrl, "_self")}
                                    </div>
                                    :
                                    <div className="text-[#FCFEFF] text-[2.4rem] urbanist-regular">
                                        <div className="urbanist-semibold">Subtotal</div>
                                        <div className="flex justify-between items-center my-[1rem]">
                                            <div className="">{utilReducers?.plan?.totalDuration == "1" ? `${utilReducers?.plan?.totalDuration} Month ${utilReducers.planType == "fit-prime" ? "Premium Plan" : "Champion Plan"}` : `${utilReducers?.plan?.totalDuration} Months ${utilReducers.planType == "fit-prime" ? "Premium Plan" : "Champion Plan"}`}</div>
                                            <div className="">₹{utilReducers?.plan?.totalAmount}</div>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <div className="">Discount</div>
                                            <div className="">{!utilReducers?.plan?.offers ? "₹" + utilReducers?.plan?.offers[0]?.offerValue : 0}</div>
                                        </div>
                                        <div className="my-[2.1rem] flex justify-around md:justify-between items-center border border-white p-4 rounded-[1.2rem]">
                                            {/* <div className=" text-[1.8rem]">
                                                {"Get Additional discount of ₹" + (utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.offerValue : 0)}
                                            </div> */}
                                            <input type="text" value={coupon} onChange={(e) => setCoupon(e.target.value)} className="bg-transparent outline-none" placeholder='Apply coupon' />
                                            <button
                                                className="urbanist-bold bg-[#FAFF00] enabled:hover:border-gray-400 disabled:opacity-75 text-black rounded-[2.5rem] px-[4rem] sm:px-[2rem] 2xl:px-[4rem] py-[.8rem]"
                                                // disabled={utilReducers?.plan?.offers == null}
                                                onClick={() => {
                                                    if (coupon == "") {
                                                        Toast("Please enter coupon code", "error")
                                                    } else {
                                                        Toast("Coupon not Applied", "error")
                                                    }
                                                }}
                                            >
                                                {offerApplied ? 'Applied' : 'Apply'}
                                            </button>
                                        </div>
                                        <div className="flex justify-between items-center mt-[1.9rem] mb-[1.5rem]">
                                            <div className="">Total</div>
                                            <div className="">₹{utilReducers?.plan?.totalAmount}</div>
                                        </div>
                                        <div className="flex justify-between items-center">
                                            <div className="">Tax</div>
                                            <div className="">₹{totalTax ? totalTax : ((totalAmount ? totalAmount : totalAmountWithoutDiscount) * 18) / 100}</div>
                                        </div>
                                        <div className="border-t border-white my-[1.5rem]"></div>
                                        <div className="flex justify-between items-center">
                                            <div className="urbanist-semibold">Net Payable</div>
                                            <div className="flex justify-end items-center">
                                                {/* <div className="text-[#A1A1A1] line-through mr-[1.6rem]">₹{totalAmountWithoutDiscount} </div> */}
                                                <div className="">₹{totalAmount ? totalAmount : totalAmountWithoutDiscount} </div>
                                            </div>
                                        </div>
                                        <button
                                            className="w-full flex justify-center items-center bg-[#FAFF00] mt-[4.3rem] urbanist-semibold text-black rounded-[1.2rem] py-[1.4rem]"
                                            onClick={() => {
                                                if (termsAndCondition == false) {
                                                    return
                                                } else {
                                                    dispatch(OrderCreate({
                                                        "orderType": 1,
                                                        "orderNumber": "",
                                                        "orderDate": new Date(),
                                                        "customerId": utilReducers?.userId,
                                                        "billingAddress": {
                                                            "line1": "",
                                                            "line2": "",
                                                            "city": "",
                                                            "pincode": "",
                                                            "state": "",
                                                            "country": "",
                                                            "zone": ""
                                                        },
                                                        "orderItems": [
                                                            {
                                                                "type": utilReducers.setUpgradeUnable ? 3 : 1,
                                                                "refId": utilReducers?.plan?.id,
                                                                "refName": utilReducers?.plan?.title,
                                                                "itemRefCode": utilReducers?.plan?.packagecode,
                                                                "refSubscriptionCategoryCode": utilReducers?.plan?.subscriptionCategoryCode,
                                                                "itemDetails": ""
                                                            }
                                                        ],
                                                        "offersApplied": [
                                                            {
                                                                "offerId": utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.id : "",
                                                                "offerCode": utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.offerCode : "",
                                                                "offerName": utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.offerName : "",
                                                                "description": utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.description : "",
                                                                "offerType": utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.offerType : 1,
                                                                "offerValue": utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.offerValue : "",
                                                                "offerValueType": utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.offerValueType : "",
                                                            }
                                                        ],
                                                        "mrp": utilReducers?.plan?.totalAmount,
                                                        "upgradeAmount": 0,
                                                        "totalDiscountAmount": utilReducers?.plan?.offers != null ? utilReducers?.plan?.offers[0]?.offerValue : 0,
                                                        "taxes": totalTax ? totalTax : 0,
                                                        "totalpayable": totalAmount,
                                                        "startdate": getDateTime(),
                                                        "venueId": "",
                                                        "trainerUserId": "",
                                                        "transactionId": "",
                                                        "orderStatus": 1,
                                                        "transactionStatus": 1,
                                                        "paymentMode": "",
                                                        "paymentGateway": "",
                                                        "invoiceNumber": "",
                                                        "invoiceDate": "",
                                                        "clientAppType": "web",
                                                        "extendedProperties": [{
                                                            "Name": "Redeem Points",
                                                            "Value": [0]
                                                        }],
                                                        "transactionSummary": {
                                                            "txnStatus": "",
                                                            "txnMsg": "",
                                                            "txnErrMsg": "",
                                                            "tpslBankCd": "",
                                                            "tpslTxnId": "",
                                                            "tpslTxnTime": "",
                                                            "bankTransactionID": "",
                                                            "txnMerchantId": ""
                                                        },
                                                    }));
                                                    dispatch(setUpgradeUnable(false));
                                                }
                                            }}
                                        >
                                            {!refreshTokenReducerLoading || !orderCreateReducerLoading || !makePaymentReducerLoading ?
                                                "Pay Now"
                                                :
                                                <div className="flex justify-center items-center">
                                                    <Oval
                                                        visible={true}
                                                        height="25"
                                                        width="25"
                                                        color="#000000"
                                                        ariaLabel="oval-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass="w-full"
                                                    />
                                                </div>
                                            }
                                        </button>
                                        {!termsAndCondition ?
                                            <span className="text-[#ff0000] text-[1.4rem] absolute bottom-2 left-20">Please Check & Agree to All Terms and Conditions</span>
                                            :
                                            null
                                        }
                                    </div>
                            :
                            <div className="flex justify-center items-center w-full">
                                <Oval
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#000000"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>

                        }
                    </div>
                    <div className="flex justify-between items-center my-[2rem]">
                        <div className="urbanist-semibold text-[#FEFDFF] text-[2.4rem]">
                            Download the Fit.zone app
                        </div>
                        <div className="flex justify-between items-center">
                            <img src={DownloadAppLandingImg.appleIcon} alt="" className="w-[14.2rem] h-full mr-[2.6rem]" />
                            <img src={DownloadAppLandingImg.googleIcon} alt="" className="w-[14.2rem] h-full" />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PayStepForm