import { actionTypes } from '../../types';

export const OrderTransactionStatus = (payload) => ({
    type: actionTypes.ORDER_TRANSACTION_STATUS_REQUEST,
    payload,
});

export const OrderTransactionStatusSuccess = (payload) => ({
    type: actionTypes.ORDER_TRANSACTION_STATUS_SUCCESS,
    payload,
});

export const OrderTransactionStatusFailure = (payload) => ({
    type: actionTypes.ORDER_TRANSACTION_STATUS_FAILURE,
    payload,
});

export const OrderTransactionStatusReset = () => ({
    type: actionTypes.ORDER_TRANSACTION_STATUS_RESET,
});

export const OrderTransactionStatusResetAll = () => ({
    type: actionTypes.ORDER_TRANSACTION_STATUS_RESET_ALL,
}); 