import React from 'react'
// import LandingImg1 from '../../../static//images//home-landing-crousal-item1.png'
import "./HomeLandingCarousel.css"
import { useNavigate } from 'react-router-dom'
import {BsChevronDoubleLeft, BsChevronDoubleRight, BsChevronLeft, BsChevronRight, BsCopy} from "react-icons/bs";
import {useToast} from "../../../hooks/useToast";

function HomeLandingCrousalItem1({ genericoffersItems }) {

    const navigate = useNavigate()
    const Toast = useToast()

    console.log("genericoffersItems :", genericoffersItems?.posterimageurlWebsite);


    const handleCopy = async (text) => {
        try {
            await navigator.clipboard.writeText(text); // Copy text to clipboard
            Toast('Copied to clipboard!', 'success'); // Set success message
        } catch (err) {
            Toast('Failed to copy', 'error'); // Error handling
        }
    };

    return (
        <div className={`px-[4rem]`} style={{ backgroundImage: `url("${genericoffersItems?.posterimageurlWebsite}")`, minHeight: "80rem", height: "98vh", backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center" }} /* onClick={() => navigate("/fit-center/fit-prime")} */>
            <div className="flex justify-between items-end h-full relative">
                <div
                    className="absolute flex justify-start items-end bg-[#333333] text-[#fff] right-0 bottom-[1.4rem] px-[2rem] py-[.6rem] rounded-[1rem] cursor-pointer"
                    onClick={() => {handleCopy(genericoffersItems?.offerCode)}}
                >
                    <div className="home-landing-crousal-item1-text urbanist-semibold text-[2rem] mr-[4rem] cursor-pointer" >
                        {genericoffersItems?.offerName}
                    </div>
                    <div className="flex items-center home-landing-crousal-item1-text text-[1.8rem] urbanist-regular cursor-pointer hs-tooltip">
                        {genericoffersItems?.offerCode}
                        <BsCopy className="ml-[1.2rem]"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeLandingCrousalItem1
