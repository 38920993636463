import React from 'react'
import "./SupportLanding.css"

const SupportLanding = () => {

    return (
        <div className="flex justify-center items-center text-white mt-[21rem] mb-[8rem]">
            <div className="flex flex-col justify-center items-center w-full md:w-[40%]">
                <div className="text-[5rem] dm-sans-bold">
                    FITZONE Support
                </div>
                <div className="w-full relative">
                    <div className="bg-[#4F4F4F] border border-[#EFF0F6] rounded-[6rem] md:relative w-[70%] md:w-full flex justify-between items-center py-5 md:py-[1.5rem] px-[2.9rem] my-[8.1rem]">
                        <input type="text" className='w-full bg-[#4F4F4F] outline-none dm-sans-regular text-white text-[2.4rem] placeholder-white' placeholder='Please post your queries' />
                        <button className="bg-[#FAFF00] absolute right-16 [@media(max-width:400px)]:right-5 sm:right-20 md:right-5 rounded-[5.6rem] px-[3.2rem] md:py-[1rem] my-[1rem] py-[1.6rem] text-black dm-sans-bold text-[1.8rem]">Search</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportLanding