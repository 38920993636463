import React from 'react'
import HomeLandingCrousalItem1 from './HomeLandingCarouselItem1';
import HomeLandingCrousalItem2 from './HomeLandingCarouselItem2';
import HomeLandingCrousalItem3 from './HomeLandingCarouselItem3';
import { register } from 'swiper/element/bundle';
import "swiper/css";
import 'swiper/element/css/pagination';
import "./HomeLandingCarousel.css"
import { useSelector } from 'react-redux';

function HomeLandingCarousel() {
    register();

    const offerSearchReducerData = useSelector((state) => state.offerSearchReducer?.data);
    // const offerSearchReducerStatus = useSelector((state) => state.offerSearchReducer?.status);

    return (
        <div className='home-landing-swiper bg-[#1E1E1E]'>
            <swiper-container pagination="true" keyboard="true" autoplay-delay="5000">
                {offerSearchReducerData?.genericoffers?.length > 0 ?
                    offerSearchReducerData?.genericoffers?.filter((item) => { return item?.applicableCustomerApplicationTypes[0] == 1 }).
                        map((genericoffersItems, index) => {
                            return (
                                <swiper-slide key={index}>
                                    <HomeLandingCrousalItem1 genericoffersItems={genericoffersItems} />
                                </swiper-slide>
                            )
                        })
                    :
                    <div className=""></div>
                }
                {/* <swiper-slide>
                    <HomeLandingCrousalItem2 />
                </swiper-slide>
                <swiper-slide>
                    <HomeLandingCrousalItem3 />
                </swiper-slide> */}
            </swiper-container>
        </div>
    )
}

export default HomeLandingCarousel
