import React from 'react'
import { MdStarRate } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const ExploreOtherGymCard = ({ img, icon, type, title, address, ratingStar, totalRating, marginTop, id }) => {

    const navigate = useNavigate();

    const ratingIcons = [];
    for (let i = 0; i < ratingStar; i++) {
        ratingIcons.push(<MdStarRate color='white' size={18} className='' />);
    }
    return (
        <div className={`${marginTop > 0 ? "my-[9.5rem]" : ""} w-full sm:w-[85%] md:w-[65%] lg:w-[31%]`}>
            <div className="relative">
                <img src={img} alt="" className="w-full h-[36.6rem] rounded-[2rem]" />
                <div className="absolute bottom-10 left-5 flex justify-between items-center">
                    <img src={icon} alt="" className={`mr-5 ${type === "Premium" ? "h-[2.5rem] w-[3.4rem]" : "h-[4.4rem] w-[3.3rem]"}`} />
                    <div className={`${type === "premium" ? "text-white" : "text-[#FFBB33]"} montserrat-bold text-[2.4rem]`}>{type}</div>
                </div>
            </div>
            <div className="urbanist-semibold text-white text-[2.8rem] mt-[2rem]">
                {title}
            </div>
            <div className="urbanist-regular text-white text-[1.8rem] my-[0.5rem]">
                {address}
            </div>
            <div className="urbanist-light text-white text-[1.4rem] flex justify-start items-center">
                <div className="flex justify-start items-center">
                    {ratingIcons}
                </div>
                <div className="mx-5 mt-1">
                    {totalRating}
                </div>
            </div>
            <button
                className="bg-[#FAFF00] text-[1.6rem] text-black urbanist-semibold rounded-[2.5rem] px-[3rem] py-[1.4rem] mt-[1.5rem]"
                onClick={() => navigate(`/fit-center/${type == "premium" ? "fit-prime" : "fit-champ"}/gym/${id}`)}
            >
                Explore gym
            </button>
        </div>
    )
}

export default ExploreOtherGymCard