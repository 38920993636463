import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import "./Membership.css"
import { subscription, subscriptionReset } from '../../../store/actions/SubscriptionManagementActions/subscriptionActions';
import { useDispatch, useSelector } from 'react-redux';
import MembershipPackagesCard from '../../common/MembershipPackages/MembershipPackagesCard';
import "swiper/css";
import { register } from 'swiper/element/bundle';
import 'swiper/element/css/pagination';

function Membership({ isHome, isHeadingNotShow }) {
    register();

    const dispatch = useDispatch();
    const [screenSize] = useState(window.innerWidth);

    const subscriptionReducersData = useSelector((state) => state.subscriptionReducers?.data);
    const subscriptionReducersStatus = useSelector((state) => state.subscriptionReducers?.status);

    const [packageType, setPackageType] = useState("Prime");
    const subscriptionUpgradeSummaryReducerData = useSelector((state) => state.subscriptionUpgradeSummaryReducer?.data);
    const subscriptionUpgradeSummaryReducerStatus = useSelector((state) => state.subscriptionUpgradeSummaryReducer?.status);
    const subscriptionUpgradeSummaryReducerLoading = useSelector((state) => state.subscriptionUpgradeSummaryReducer?.loading);


    useEffect(() => {
        dispatch(subscription());
    }, [])

    useEffect(() => {
        if (subscriptionReducersStatus == 200) {
            dispatch(subscriptionReset());
        } else if (subscriptionReducersStatus) {
            dispatch(subscriptionReset());
        }
    }, [subscriptionReducersStatus])

    return (
        <div className={`membership-section select-none overflow-hidden ${!isHome && "membership-bg"}`}>
            <div className="container-lg flex flex-col justify-center items-center max-h-full">
                {isHeadingNotShow ?
                    null
                    :
                    <div className="text-white text-[3.2rem] md:text-[6.5rem] montserrat-bold text-center">
                        EXPLORE<br className='md:hidden' /> OUR PLANS
                    </div>
                }
                {isHeadingNotShow ?
                    null
                    :
                    <div className="text-white text-[2.4rem] my-[1rem] questrial-regular text-center">
                        Tailored plans for endurance and performance
                    </div>
                }
                <div className={`flex justify-center items-center w-[40rem] h-[5.7rem] ${isHeadingNotShow ? "" : ""}`} /* onClick={()=> setIsOn(!isOn)} */>
                    <motion.div
                        className={`flex justify-center items-center w-[40rem] h-[5.7rem] relative rounded-full bg-[#D9D9D9]`}
                        layout
                    >
                        <div className='text-[1.6rem] cursor-pointer montserrat-bold flex justify-center items-center w-[20rem] h-[5.7rem]' onClick={() => { setPackageType("Prime") }}>Prime</div>
                        <div className='text-[1.6rem] cursor-pointer montserrat-bold flex justify-center items-center w-[20rem] h-[5.7rem]' onClick={() => { setPackageType("Champ") }}>Champ</div>
                        <motion.span
                            className="absolute bg-[#FFF200] text-[1.6rem] cursor-pointer montserrat-bold flex justify-center items-center w-[20rem] h-[5.7rem] rounded-full"
                            initial={{ x: 0 }}
                            animate={{ x: packageType === "Prime" ? '-10rem' : '10rem' }}
                            transition={{ type: 'spring', stiffness: 700, damping: 30, duration: 0.3 }}
                            key={packageType === "Prime" ? 'on' : 'off'}
                        >
                            {packageType === "Prime" ? 'Prime' : 'Champ'}
                        </motion.span>
                    </motion.div>
                </div>
            </div>
            {isHeadingNotShow ?
                <div className="my-5">
                    {packageType === "Prime" ?
                        screenSize > 1024 ?
                            <div className="flex justify-center items-center gap-[2.4rem]">
                                {subscriptionUpgradeSummaryReducerData?.length > 0 ?
                                    subscriptionUpgradeSummaryReducerData?.filter((item) => item?.package?.subscriptionCategoryName == "Premium")?.map((items, index) => {
                                        if (index > 3) {
                                            return null
                                        } else {
                                            return (
                                                <div className="">
                                                    <MembershipPackagesCard
                                                        items={items?.package}
                                                        package_type={"fit-prime"}
                                                        planDuration={items?.package.totalDuration?.toFixed()}
                                                        price={items?.package.totalAmount / items?.package.totalDuration}
                                                        billedAmount={items?.package.totalAmount}
                                                        points={items?.package.description}
                                                        id={items?.package.id}
                                                        isHeadingNotShow={isHeadingNotShow}
                                                        isHome={isHome}
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                    :
                                    <div className="text-[1.6rem] montserrat-bold text-white">No Packages Available</div>
                                }
                            </div>
                            :
                            <div className="">
                                <swiper-container pagination="true" className="membership-packages-swiper">
                                    {subscriptionUpgradeSummaryReducerData?.length > 0 ?
                                        subscriptionUpgradeSummaryReducerData?.filter((item) => item?.package?.subscriptionCategoryName == "Premium")?.map((items, index) => {
                                            if (index > 3) {
                                                return null
                                            } else {
                                                return (
                                                    <swiper-slide>
                                                        <div className="flex justify-center items-center">
                                                            <MembershipPackagesCard
                                                                items={items?.package}
                                                                package_type={"fit-prime"}
                                                                planDuration={items?.package.totalDuration?.toFixed()}
                                                                price={items?.package.totalAmount / items?.package.totalDuration}
                                                                billedAmount={items?.package.totalAmount}
                                                                points={items?.package.description}
                                                                id={items?.package.id}
                                                                isHome={isHome}
                                                            />
                                                        </div>
                                                    </swiper-slide>
                                                )
                                            }
                                        })
                                        :
                                        <div className="text-[1.6rem] montserrat-bold text-white text-center w-full">No Packages Available</div>
                                    }
                                </swiper-container>
                            </div>
                        :
                        screenSize > 1024 ?
                            <div className="flex justify-center items-center gap-[2.4rem]">
                                {subscriptionUpgradeSummaryReducerData?.length > 0 ?
                                    subscriptionUpgradeSummaryReducerData?.filter((item) => item?.package?.subscriptionCategoryName == "Champion")?.map((items, index) => {
                                        if (index > 3) {
                                            return null
                                        } else {
                                            return (
                                                <div className="">
                                                    <MembershipPackagesCard
                                                        items={items?.package}
                                                        package_type={"fit-champ"}
                                                        planDuration={items?.package.totalDuration?.toFixed()}
                                                        price={items?.package.totalAmount / items?.package.totalDuration}
                                                        billedAmount={items?.package.totalAmount}
                                                        points={items?.package.description}
                                                        id={items?.package.id}
                                                        isHome={isHome}
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                    :
                                    <div className="text-[1.6rem] montserrat-bold text-white w-full text-center">No Packages Available</div>
                                }
                            </div>
                            :
                            <div className="">
                                <swiper-container pagination="true" className="membership-packages-swiper">
                                    {subscriptionUpgradeSummaryReducerData?.length > 0 ?
                                        subscriptionUpgradeSummaryReducerData?.filter((item) => item.subscriptionCategoryName == "Champion")?.map((items, index) => {
                                            if (index > 3) {
                                                return null
                                            } else {
                                                return (
                                                    <swiper-slide>
                                                        <div className="flex justify-center items-center">
                                                            <MembershipPackagesCard
                                                                items={items?.package}
                                                                package_type={"fit-champ"}
                                                                planDuration={items?.package.totalDuration?.toFixed()}
                                                                price={items?.package.totalAmount / items?.package.totalDuration}
                                                                billedAmount={items?.package.totalAmount}
                                                                points={items?.package.description}
                                                                id={items?.package.id}
                                                                isHome={isHome}
                                                            />
                                                        </div>
                                                    </swiper-slide>
                                                )
                                            }
                                        })
                                        :
                                        <div className="text-[1.6rem] montserrat-bold text-white w-full text-center">No Packages Available</div>
                                    }
                                </swiper-container>
                            </div>
                    }
                </div>
                :
                <div className={`my-5`}>
                    {packageType === "Prime" ?
                        screenSize > 1024 ?
                            <div className="flex justify-center items-center gap-[2.4rem]">
                                {subscriptionReducersData?.subscriptions?.length > 0 ?
                                    subscriptionReducersData?.subscriptions?.filter((item) => item.subscriptionCategoryName == "Premium")?.map((items, index) => {
                                        if (index > 3) {
                                            return null
                                        } else {
                                            return (
                                                <div className="">
                                                    <MembershipPackagesCard
                                                        items={items}
                                                        package_type={"fit-prime"}
                                                        planDuration={items.totalDuration?.toFixed()}
                                                        price={items.totalAmount / items.totalDuration}
                                                        billedAmount={items.totalAmount}
                                                        points={items.description}
                                                        id={items.id}
                                                        isHeadingNotShow={isHeadingNotShow}
                                                        isHome={isHome}
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                    :
                                    <div className="text-[1.6rem] montserrat-bold text-white w-full text-center">No Packages Available</div>
                                }
                            </div>
                            :
                            <div className="">
                                <swiper-container pagination="true" className="membership-packages-swiper">
                                    {subscriptionReducersData?.subscriptions?.length > 0 ?
                                        subscriptionReducersData?.subscriptions?.filter((item) => item.subscriptionCategoryName == "Premium")?.map((items, index) => {
                                            if (index > 3) {
                                                return null
                                            } else {
                                                return (
                                                    <swiper-slide>
                                                        <div className="flex justify-center items-center">
                                                            <MembershipPackagesCard
                                                                items={items}
                                                                package_type={"fit-prime"}
                                                                planDuration={items.totalDuration?.toFixed()}
                                                                price={items.totalAmount / items.totalDuration}
                                                                billedAmount={items.totalAmount}
                                                                points={items.description}
                                                                id={items.id}
                                                                isHome={isHome}
                                                            />
                                                        </div>
                                                    </swiper-slide>
                                                )
                                            }
                                        })
                                        :
                                        <div className="text-[1.6rem] montserrat-bold text-white w-full text-center">No Packages Available</div>
                                    }
                                </swiper-container>
                            </div>
                        :
                        screenSize > 1024 ?
                            <div className="flex justify-center items-center gap-[2.4rem]">
                                {subscriptionReducersData?.subscriptions?.length > 0 ?
                                    subscriptionReducersData?.subscriptions?.filter((item) => item.subscriptionCategoryName == "Champion")?.map((items, index) => {
                                        if (index > 3) {
                                            return null
                                        } else {
                                            return (
                                                <div className="">
                                                    <MembershipPackagesCard
                                                        items={items}
                                                        package_type={"fit-champ"}
                                                        planDuration={items.totalDuration?.toFixed()}
                                                        price={items.totalAmount / items.totalDuration}
                                                        billedAmount={items.totalAmount}
                                                        points={items.description}
                                                        id={items.id}
                                                        isHome={isHome}
                                                    />
                                                </div>
                                            )
                                        }
                                    })
                                    :
                                    <div className="text-[1.6rem] montserrat-bold text-white w-full text-center">No Packages Available</div>
                                }
                            </div>
                            :
                            <div className="">
                                <swiper-container pagination="true" className="membership-packages-swiper">
                                    {subscriptionReducersData?.subscriptions?.length > 0 ?
                                        subscriptionReducersData?.subscriptions?.filter((item) => item.subscriptionCategoryName == "Champion")?.map((items, index) => {
                                            if (index > 3) {
                                                return null
                                            } else {
                                                return (
                                                    <swiper-slide>
                                                        <div className="flex justify-center items-center">
                                                            <MembershipPackagesCard
                                                                items={items}
                                                                package_type={"fit-champ"}
                                                                planDuration={items.totalDuration?.toFixed()}
                                                                price={items.totalAmount / items.totalDuration}
                                                                billedAmount={items.totalAmount}
                                                                points={items.description}
                                                                id={items.id}
                                                                isHome={isHome}
                                                            />
                                                        </div>
                                                    </swiper-slide>
                                                )
                                            }
                                        })
                                        :
                                        <div className="text-[1.6rem] montserrat-bold text-white w-full text-center">No Packages Available</div>
                                    }
                                </swiper-container>
                            </div>
                    }
                </div>
            }

        </div>
    )
}

export default Membership
