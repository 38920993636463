import React, { useEffect, useState } from 'react'
import { CommunityImg } from "../../static/constants/imgConstants";
import FeaturedArticles from '../../components/common/FeaturedArticles/FeaturedArticles';
import ExploreCategories from '../../components/common/ExploreCategories/ExploreCategories';
import Footer3 from '../../components/common/Footer3/Footer3';
import { useParams } from 'react-router-dom';
import { community } from '../../store/actions/StrapiServiceActions/communityAction';
import { useDispatch, useSelector } from 'react-redux';
import AllBlogsComponent from '../../components/common/AllBlogsComponent/AllBlogsComponent';
import { communitySubCategories } from '../../store/actions/StrapiServiceActions/communitySubCategoriesAction';

function AllBlogs() {

    const { category } = useParams()

    const dispatch = useDispatch()

    const communityReducerData = useSelector((state) => state.communityReducer?.data);
    const communitySubCategoriesReducerData = useSelector((state) => state.communitySubCategoriesReducer?.data);

    useEffect(() => {
        dispatch(community());
        dispatch(communitySubCategories())
    }, [])

    console.log("all blogs category :", category);


    return (
        <div className='bg-[#000000] md:bg-[#1E1E1E]'>
            <div className='flex justify-center my-[4rem]'>
                <AllBlogsComponent cards={communityReducerData.length > 0 ? communityReducerData?.filter((item) => { return item.sub_category.sub_category_name == category }) : []} />
            </div>
            <div className="flex justify-center items-center">
                <button className='bg-[#FAFF00] text-[#486077] text-[1.7rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold'>
                    Load More
                </button>
            </div>
            <div className='py-[4rem]'>
                <ExploreCategories data={communitySubCategoriesReducerData} selectedCategory={category} />
            </div>
            <Footer3 />
        </div>
    )
}

export default AllBlogs
