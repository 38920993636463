import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_subscription_management_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { offerSearchFailure, offerSearchSuccess } from '../../actions/SubscriptionManagementActions/offerSearchAction';

function* offerSearch({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'get',
            base_url: base_url_subscription_management_service,
            endPoint: endpoints.offerSearch + `?searchkey=isBannerOffer&searchvalue=true&sortfield=OfferName&pagesize=100&pagenumber=1&order=1`,
            isAuth: false,
        });

        console.log('====================================');
        console.log("OFFER_SEARCH_SUCCESS status :", response.status);
        console.log("OFFER_SEARCH_SUCCESS data :", response.data);
        console.log("OFFER_SEARCH_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            offerSearchSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("OFFER_SEARCH_FAILURE status :", error?.response?.status);
        console.log("OFFER_SEARCH_FAILURE data:", error?.response?.data);
        console.log("OFFER_SEARCH_FAILURE error :", error);
        console.log('====================================');
        yield put(offerSearchFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* offerSearchSaga() {
    yield takeLatest(actionTypes.OFFER_SEARCH_REQUEST, offerSearch);
}

export default offerSearchSaga;