import moment from 'moment';
import React, { useState } from 'react'
import { FaChevronRight } from 'react-icons/fa';
import { IoChevronDownCircle } from 'react-icons/io5';
import { MdOutlineFileDownload } from "react-icons/md";
import { useDispatch } from 'react-redux';
import { downloadOrderInvoicePdf } from '../../../store/actions/orderManagementActions/downloadOrderInvoicePdfAction';

const OrderInvoiceCard = ({ index, data }) => {

    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className='w-full mt-[2rem]'>
            <div className="m-2 space-y-2">
                <div
                    className={`group flex flex-col bg-[#4F4F4F] ${isOpen ? 'border focus:border-[#FAFF00]' : ''} rounded-[1.4rem] px-[2rem] sm:px-[3.8rem] py-[2rem] sm:py-[3.5rem] text-white rounded-[1.4rem]`}
                    tabIndex={`${index + 1}`}
                    onClick={handleToggle}
                // onBlur={() => setIsOpen(false)}
                >
                    <div className="flex cursor-pointer items-center justify-between">
                        <span className={`dm-sans-bold text-[2rem]`}>{`Invoice ${data.orderNumber}`}</span>
                        <IoChevronDownCircle color={"#FAFF00"} size={40} className={`transition-all duration-500 ${isOpen ? 'block' : 'hidden'}`} />
                        <FaChevronRight color={"white"} size={22} className={`transition-all duration-500 ${isOpen ? 'hidden' : 'block'}`} />
                    </div>
                    <div
                        className={`h-auto w-full dm-sans-regular text-[1.8rem] items-center overflow-hidden transition-all ${isOpen ? 'max-h-screen opacity-100 my-[0.6rem] duration-1000' : 'max-h-0 opacity-0 invisible'}`}
                    >
                        <div className="flex flex-col justify-between gap-6 mt-[1rem]">
                            <div className="flex justify-between items-center">
                                <div className="text-white">Package Name</div>
                                {data.orderItems?.map((items) => {
                                    return (
                                        <div className="">
                                            {items.refName}
                                        </div>
                                    )
                                })
                                }
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="text-white">Order Date</div>
                                <div className="">{moment(data.startDate).format("MMM Do YY, h:mm A")}</div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="text-white">Payment Mode</div>
                                <div className="">{data.paymentMode ? data.paymentMode : "--"}</div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="text-white">Total MRP</div>
                                <div className="">₹{data.mrp}</div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="text-white">Discount</div>
                                <div className="">{data.totalDiscountAmount}</div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className="text-white">CGST + SGST</div>
                                <div className="">{data.taxes}</div>
                            </div>
                            <div className="border-t-2 border-white"></div>
                            <div className="flex justify-between items-center">
                                <div className="text-white">Total Payment</div>
                                <div className="">₹{data.totalpayable}</div>
                            </div>
                            <div className="flex justify-start items-center">
                                <button
                                    className="bg-[#FAFF00] text-black montserrat-bold text-[1.5rem] px-[2rem] py-[1rem] rounded-[1rem] flex justify-start items-center"
                                    onClick={() => { dispatch(downloadOrderInvoicePdf({ orderId: data.id })); setIsOpen(true) }}
                                >
                                    <MdOutlineFileDownload color='black' size={28} className='mr-2' />
                                    Download Invoice
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrderInvoiceCard