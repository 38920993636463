import { put, call, takeLatest } from 'redux-saga/effects';
import { base_url_leadgen_service, endpoints } from '../../constants';
import { AxiosService } from '../../services';
import { actionTypes } from '../../types';
import { subscribeFailure, subscribeSuccess } from '../../actions/leadgenServiceActions/subscribeAction';
// import store from '../../configStore';

function* subscribe({ payload }) {
    try {
        const response = yield call(AxiosService, {
            method: 'post',
            base_url: base_url_leadgen_service,
            endPoint: endpoints.subscribe,
            data: payload,
            isAuth: false,
        });

        console.log('====================================');
        console.log("SUBSCRIBE_SUCCESS status :", response.status);
        console.log("SUBSCRIBE_SUCCESS data :", response.data);
        console.log("SUBSCRIBE_SUCCESS message :", response.statusText);
        console.log('====================================');
        yield put(
            subscribeSuccess({
                status: response?.status,
                data: response?.data,
                message: response.statusText
            })
        );
    } catch (error) {
        console.log('====================================');
        console.log("SUBSCRIBE_FAILURE status :", error?.response?.status);
        console.log("SUBSCRIBE_FAILURE data:", error?.response?.data);
        console.log("SUBSCRIBE_FAILURE error :", error);
        console.log('====================================');
        yield put(subscribeFailure({
            status: error?.response?.status,
            message: error?.response?.data?.statusmessage
        }));
    }
}

function* subscribeSaga() {
    yield takeLatest(actionTypes.SUBSCRIBE_REQUEST, subscribe);
}

export default subscribeSaga;