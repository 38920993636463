import React, { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { FaChevronUp } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { createTicket, createTicketReset, createTicketResetAll } from '../../../store/actions/helpdeskServiceActions/createTicketAction';
import { useToast } from '../../../hooks/useToast';
import { getTicketByUserId, getTicketByUserIdReset } from '../../../store/actions/helpdeskServiceActions/getTicketByUserIdAction';
import { Oval } from 'react-loader-spinner';
import FAQSectionItem from '../FAQSection/FAQSectionItem';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Strapifaqs } from '../../../store/actions/StrapiServiceActions/StrapifaqsAction';

const Support = ({ userId }) => {


    const dispatch = useDispatch()
    const Toast = useToast()
    const navigate = useNavigate();

    const utilReducers = useSelector((state) => state.utilReducers);
    const CreateTicketReducerStatus = useSelector((state) => state.createTicketReducer.status);
    const CreateTicketReducerMessage = useSelector((state) => state.createTicketReducer.message);
    const CreateTicketReducerLoading = useSelector((state) => state.createTicketReducer.loading);
    const getTicketByUserIdReducerStatus = useSelector((state) => state.getTicketByUserIdReducer.status);
    const getTicketByUserIdReducerData = useSelector((state) => state.getTicketByUserIdReducer.data);
    const getTicketByUserIdReducerLoading = useSelector((state) => state.getTicketByUserIdReducer.loading);
    const strapifaqsReducerData = useSelector((state) => state.strapifaqsReducer.data);

    const [isOpen, setIsOpen] = useState({
        ticketHistory: false,
        faq: false,
        contactUs: false,
    });

    const [showForm, setShowForm] = useState(false);
    const [subject, setSubject] = useState("")
    const [description, setDescription] = useState("")

    const toggleSection = (section) => {
        setIsOpen((prev) => {
            const newState = {
                ticketHistory: false,
                faq: false,
                contactUs: false,
            };
            return {
                ...newState,
                [section]: !prev[section],
            };
        });
    };

    useEffect(() => {
        dispatch(createTicketResetAll());
        dispatch(getTicketByUserIdReset());
        dispatch(getTicketByUserId({ userEmail: utilReducers?.email }))
        dispatch(Strapifaqs({ category: "my-account" }));
    }, [])


    useEffect(() => {
        if (CreateTicketReducerStatus == 200) {
            Toast(CreateTicketReducerMessage ? CreateTicketReducerMessage : "Ticket Created Successfully!")
            dispatch(createTicketReset());
            dispatch(getTicketByUserId({ userEmail: utilReducers?.email }))
            setShowForm(false);
        } else if (CreateTicketReducerStatus) {
            Toast(CreateTicketReducerMessage ? CreateTicketReducerMessage : "Ticket Created right now! Please try again later")
            dispatch(createTicketReset());
        }
    }, [CreateTicketReducerStatus])

    useEffect(() => {
        if (getTicketByUserIdReducerStatus == 200) {
            dispatch(getTicketByUserIdReset());
        } else if (getTicketByUserIdReducerStatus) {
            dispatch(getTicketByUserIdReset());
        }
    }, [getTicketByUserIdReducerStatus])

    const groupBySubCategory = (strapifaqsReducerData) => {
        return strapifaqsReducerData.length > 0 ? strapifaqsReducerData?.reduce((acc, item) => {
            if (!acc[item?.SubCategory]) {
                acc[item?.SubCategory] = [];
            }
            acc[item?.SubCategory].push(item);
            return acc;
        }, {})
            :
            null;
    };

    const [groupedData, setGroupedData] = useState(null);

    useEffect(() => {
        if (strapifaqsReducerData && strapifaqsReducerData.length > 0) {
            const grouped = groupBySubCategory(strapifaqsReducerData);
            setGroupedData(grouped);
        } else {
            setGroupedData(null); // Handle empty or undefined data
        }
    }, [strapifaqsReducerData]);

    console.log("utilReducers?.email :", utilReducers.email);
    console.log("getTicketByUserIdReducerData :", getTicketByUserIdReducerData);


    return (
        <div className="bg-[#000000] h-full py-[2rem]">
            <div className="container-lg px-[4rem] bg-[#1E1E1E] rounded-[2rem] mt-[4rem] h-auto">
                <div className="flex flex-col sm:flex-row justify-between items-center p-6">
                    <h1 className="text-white text-[3rem] font-bold mb-[1rem] sm:mb-0">Support</h1>
                    <button
                        className="bg-[#FAFF00] text-[2rem] hover:text-white font-[500] text-black px-4 py-2 rounded-[0.8rem] shadow hover:bg-gray-700"
                        onClick={() => {
                            setShowForm(true);
                            if (!isOpen.ticketHistory) {
                                toggleSection('ticketHistory');
                            }
                        }}
                    >
                        + Create New Ticket
                    </button>
                </div>
                <div className="divide-y-black bg-black text-white rounded-[2rem] my-[2rem] mb-[4rem]">
                    <div
                        className="text-black py-[1rem] px-[4rem] text-[2rem] cursor-pointer flex justify-between items-center"
                        onClick={() => toggleSection('ticketHistory')}
                    >
                        <span className="font-semibold text-white">Ticket History</span>
                        <span className="bg-[#808080] text-white w-[3rem] h-[3rem] flex items-center justify-center rounded-full">
                            {isOpen.ticketHistory ? <FaChevronUp /> : <FaChevronDown />}
                        </span>
                    </div>
                    {isOpen.ticketHistory && (
                        !getTicketByUserIdReducerLoading ?
                            <div className="py-[1rem] px-[4rem] text-[1.5rem] bg-black rounded-[1rem]">
                                {showForm ? (
                                    <div className="bg-[#1E1E1E] text-white p-6 rounded-[2rem]">
                                        <h2 className="text-[2rem] text-white font-bold mb-4">Support</h2>
                                        <p className="mb-4">Your email id: {utilReducers?.email}</p>
                                        <p className="mb-4"><b>Note:</b> Updates will be sent to the above email id.</p>
                                        <form>
                                            <div className="mb-4">
                                                <label className="block text-white text-[1.5rem] font-bold mb-2" htmlFor="subject">
                                                    Subject
                                                </label>
                                                <input
                                                    className="border-black rounded-[1rem] bg-[#000000] border border-[#ffffff]/40 w-full h-[4rem] py-2 px-3 text-white outline-none"
                                                    id="subject"
                                                    type="text"
                                                    placeholder=""
                                                    value={subject}
                                                    onChange={(e) => {
                                                        if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                                            setSubject(e.target.value)
                                                        } else {
                                                            return
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="mb-4">
                                                <label className="block text-white text-[1.5rem] font-bold mb-2" htmlFor="description">
                                                    Description
                                                </label>
                                                <textarea
                                                    className="border-black rounded-[1rem] bg-[#000000] w-full py-2 px-3 text-white outline-none border border-[#ffffff]/40"
                                                    id="description"
                                                    rows="4"
                                                    placeholder=""
                                                    value={description}
                                                    onChange={(e) => {
                                                        if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
                                                            setDescription(e.target.value)
                                                        }
                                                    }
                                                    }
                                                />
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <button
                                                    className="bg-[#FAFF00] hover:bg-gray-700 text-black hover:text-white font-bold py-2 px-4 rounded-[1rem]"
                                                    type="button"
                                                    onClick={() => {
                                                        if (subject == "" || description == "") {
                                                            Toast("Please Add subject and description");
                                                            return
                                                        }
                                                        if (utilReducers?.email) {
                                                            dispatch(createTicket({
                                                                "subject": subject,
                                                                "description": description,
                                                                "email": utilReducers?.email
                                                            }))
                                                        }
                                                        if (!utilReducers?.email) {
                                                            Toast("Please Update your profile first");
                                                        }
                                                    }
                                                    }
                                                >
                                                    {
                                                        CreateTicketReducerLoading ?
                                                            <Oval
                                                                visible={true}
                                                                height="25"
                                                                width="25"
                                                                color="#FAFF00"
                                                                ariaLabel="oval-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass=""
                                                            />
                                                            :
                                                            "Submit"
                                                    }
                                                </button>
                                                <button
                                                    className="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-[1rem]"
                                                    type="button"
                                                    onClick={() => setShowForm(false)}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    getTicketByUserIdReducerData.length > 0 ?
                                        <table class="table-fixed w-full">
                                            <thead>
                                                <tr className=''>
                                                    <th className='text-[1.6rem] text-white py-[1rem]'>Ticket Number</th>
                                                    <th className='text-[1.6rem] text-white py-[1rem]'>Subject</th>
                                                    <th className='text-[1.6rem] text-white py-[1rem]'>Created Date</th>
                                                    <th className='text-[1.6rem] text-white py-[1rem]'>Due Date</th>
                                                    <th className='text-[1.6rem] text-white py-[1rem]'>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody className='bg-[#1E1E1E] rounded-[2rem]'>
                                                {getTicketByUserIdReducerData.map((data) => {
                                                    return (
                                                        <tr className='border-t-4 border-black my-[1rem]'>
                                                            <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                                                                {data.ticketNumber}
                                                            </td>
                                                            <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                                                                {data.subject}
                                                            </td>
                                                            <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                                                                {data.createdTime ? moment(data.createdTime).format('lll') : "--"}
                                                            </td>
                                                            <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                                                                {data.dueDate ? data.dueDate : "--"}
                                                            </td>
                                                            <td className='text-[1.6rem] text-[#FC5555] py-[2rem] text-center'>
                                                                {data.status}
                                                            </td>
                                                        </tr>
                                                    )
                                                })

                                                }

                                            </tbody>
                                        </table>
                                        :
                                        <div className="">
                                            No Ticket History Available
                                        </div>
                                )}
                            </div>
                            :
                            <div className="flex justify-center items-center">
                                <Oval
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#FAFF00"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                    )}
                    <div
                        className="py-[1rem] px-[4rem] text-[2rem] cursor-pointer flex justify-between items-center rounded-[1rem]"
                        onClick={() => toggleSection('faq')}
                    >
                        <span className="font-semibold ">FAQ</span>
                        <span className="bg-[#808080] text-white w-[3rem] h-[3rem] flex items-center justify-center rounded-full">
                            {isOpen.faq ? <FaChevronUp /> : <FaChevronDown />}
                        </span>
                    </div>
                    {isOpen.faq && (
                        groupedData ?
                            Object.keys(groupedData)?.length > 0 ? (
                                Object.keys(groupedData)?.map((subCategory, index) => (
                                    <div key={subCategory} className='px-20'>
                                        {/* <div className="bg-[#4F4F4F] rounded-[1.8rem]">
                                            <div className="my-[3.6rem] mx-[3.2rem] text-white dm-sans-bold text-[2.4rem]">
                                                {index + 1}. {subCategory}
                                            </div>
                                        </div> */}
                                        {/* <div className="bg-[#21211F] md:mt-[4.4rem] mb-[9.6rem] flex flex-col justify-center items-center px-[4rem] sm:px-[10rem] py-[6rem] rounded-b-[2rem] md:rounded-[2rem]"> */}
                                        {groupedData[subCategory].length <= 3 ? groupedData[subCategory]?.map((item, idx) => {
                                            if (idx <= 3) {
                                                return (
                                                    <FAQSectionItem faqSectionData={item} isPackageDetailsPageActive={true} index={index} />
                                                )
                                            }
                                        })
                                            :
                                            null
                                        }
                                        {/* </div> */}
                                    </div>
                                ))
                            ) : (
                                <div className="text-white dm-sans-bold text-[2.4rem] text-center">
                                    No data Available at this time! Please try again later...
                                </div>
                            )
                            :
                            <div className="text-white dm-sans-bold text-[2.4rem] text-center">
                                Loading data...
                            </div>
                    )}
                    <div
                        className="py-[1rem] px-[4rem] text-[2rem] cursor-pointer flex justify-between items-center rounded-[1rem]"
                        onClick={() => toggleSection('contactUs')}
                    >
                        <span className="font-semibold">Contact Us</span>
                        <span className="bg-[#808080] text-white w-[3rem] h-[3rem] flex items-center justify-center rounded-full">
                            {isOpen.contactUs ? <FaChevronUp /> : <FaChevronDown />}
                        </span>
                    </div>
                    {isOpen.contactUs && (
                        <div className="py-[1rem] px-[4rem] text-[1.5rem] bg-black border border-black">
                            <div className="p-6 bg-[#1E1E1E] rounded-[1rem] flex justify-between items-center">
                                <div className="">
                                    <p>Email Support:</p>
                                    <p className='my-5'>WhatsApp Support:</p>
                                </div>
                                <div className="">
                                    <p className="cursor-pointer">
                                        <a href="mailto:reach@fitzone.com">reach@fitzone.com</a>
                                    </p>
                                    <p className="cursor-pointer my-5">
                                        <a href="tel:+91123456789">+91 123456789</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Support;
