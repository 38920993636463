import React from 'react'
import Footer3 from '../../components/common/Footer3/Footer3'
import PackageImgAndDetails from '../../components/common/PackageImgAndDetails/PackageImgAndDetails'
import PackageStartDate from '../../components/common/PackageStartDate/PackageStartDate'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PackagesDetails = () => {

    const utilReducers = useSelector((state) => state.utilReducers);

    const { package_type, plan_duration } = useParams();

    return (
        <div className='packages-details-background bg-[#1E1E1E] px-[4rem]'>
            <div className="container-lg flex flex-col justify-center items-center lg:items-start w-full pt-[8rem]">
                <div className="urbanist-semibold text-white text-[4.8rem] my-[3rem]">
                    {package_type == "fit-prime" ? "Premium Plan" : "Champion Plan"}
                </div>
                <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start w-full">
                    <PackageImgAndDetails points={utilReducers?.plan?.description} />
                    <PackageStartDate offerDuration={utilReducers?.plan?.totalDuration} />
                </div>
            </div>
            <Footer3 />
        </div>
    )
}

export default PackagesDetails