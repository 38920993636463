import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubscriptionRecord, SubscriptionRecordReset, SubscriptionRecordResetAll } from '../../../store/actions/SubscriptionManagementActions/SubscriptionRecordAction';
import { useToast } from '../../../hooks/useToast';
import moment from 'moment/moment';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { logout, setAccessToken, setUpgradeUnable, setUserId } from '../../../store/actions/utilsActions/utilsActions';
import { pauseSubscription, pauseSubscriptionReset } from '../../../store/actions/SubscriptionManagementActions/pauseSubscriptionAction';
import { unPauseSubscription, unPauseSubscriptionReset } from '../../../store/actions/SubscriptionManagementActions/unPauseSubscriptionAction';
import { Tooltip } from 'react-tooltip';
import Membership from '../../main/Membership/Membership';
import { subscriptionUpgradeSummary } from '../../../store/actions/SubscriptionManagementActions/subscriptionUpgradeSummaryAction';
import SubscriptionsPauseModel from '../SubscriptionsPauseModel/SubscriptionsPauseModel';

const Subscriptions = () => {

    const dispatch = useDispatch();
    const Toast = useToast();

    const navigate = useNavigate()

    const [isUpgradeSectionEnable, setIsUpgradeSectionEnable] = useState(false);

    const [handleOutsideClick, setHandleOutsideClick] = useState(false);

    const [pauseModel, setPauseModel] = useState(false);

    const [SubscriptionPauseDays, setSubscriptionPauseDays] = useState("")
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("")

    const utilReducers = useSelector((state) => state.utilReducers);
    const subscriptionRecordReducerData = useSelector((state) => state.subscriptionRecordReducer.data);
    const subscriptionRecordReducerStatus = useSelector((state) => state.subscriptionRecordReducer.status);
    const subscriptionRecordReducerLoading = useSelector((state) => state.subscriptionRecordReducer.loading);
    const pauseSubscriptionReducerStatus = useSelector((state) => state.pauseSubscriptionReducer.status);
    const pauseSubscriptionReducerLoading = useSelector((state) => state.pauseSubscriptionReducer.loading);
    const pauseSubscriptionReducerMessage = useSelector((state) => state.pauseSubscriptionReducer.message);
    const unPauseSubscriptionReducerStatus = useSelector((state) => state.unPauseSubscriptionReducer.status);
    const unPauseSubscriptionReducerLoading = useSelector((state) => state.unPauseSubscriptionReducer.loading);
    const unPauseSubscriptionReducerMessage = useSelector((state) => state.unPauseSubscriptionReducer.message);

    function isRenewalButtonVisible(subscriptionData) {
        // Step 1: Define the base package and renewal window mapping
        const renewalWindows = {
            30: 15,  // 1 Month
            90: 30,  // 3 Months
            180: 45, // 6 Months
            365: 60  // 12 Months
        };

        // Step 2: Extract the necessary data
        const startDate = new Date(subscriptionData.startDate);
        const endDate = new Date(subscriptionData.endDate);
        const totalDurationInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

        // Step 3: Determine the base package duration in days
        let basePackageDuration = null;

        if (totalDurationInDays <= 30) {
            basePackageDuration = 30;
        } else if (totalDurationInDays <= 90) {
            basePackageDuration = 90;
        } else if (totalDurationInDays <= 180) {
            basePackageDuration = 180;
        } else if (totalDurationInDays <= 365) {
            basePackageDuration = 365;
        }

        // Step 4: Check if the renewal button should be visible
        const currentDate = new Date();
        const renewalWindowStartDate = new Date(endDate);
        renewalWindowStartDate.setDate(renewalWindowStartDate.getDate() - renewalWindows[basePackageDuration]);

        return currentDate >= renewalWindowStartDate && currentDate <= endDate;
    }

    // Function to calculate remaining days to package expiry
    function calculateRemainingDays(subscriptionData) {
        const currentDate = new Date();
        const endDate = new Date('2024-12-08T20:10:16Z');
        const remainingTime = endDate - currentDate;
        console.log("remainingTime :", Math.ceil(remainingTime / (1000 * 60 * 60 * 24)))
        return Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
    }

    function isUpgradeButtonVisible(subscriptionData) {
        console.log("isUpgradeButtonVisible subscriptionData :", subscriptionData);

        // Step 1: Define the base package and upgrade window mapping
        const upgradeWindows = {
            30: 15,  // 1 Month
            90: 30,  // 3 Months
            180: 45, // 6 Months
            365: 60  // 12 Months
        };

        // Step 2: Extract the necessary data
        const startDate = new Date(subscriptionData?.startDate);
        const endDate = new Date(subscriptionData?.endDate);
        const totalDurationInDays = (endDate - startDate) / (1000 * 60 * 60 * 24);

        // Step 3: Determine the base package duration in days
        let basePackageDuration = null;

        if (totalDurationInDays <= 30) {
            basePackageDuration = 30;
        } else if (totalDurationInDays <= 90) {
            basePackageDuration = 90;
        } else if (totalDurationInDays <= 180) {
            basePackageDuration = 180;
        } else if (totalDurationInDays <= 365) {
            basePackageDuration = 365;
        }

        // Step 4: Check if the upgrade button should be visible
        const currentDate = new Date();
        const upgradeWindowEndDate = new Date(startDate);
        upgradeWindowEndDate.setDate(upgradeWindowEndDate.getDate() + upgradeWindows[basePackageDuration]);

        return currentDate >= startDate && currentDate <= upgradeWindowEndDate

    }

    useEffect(() => {
        dispatch(SubscriptionRecordResetAll());
        dispatch(SubscriptionRecord({ userId: utilReducers?.userId }))

    }, [])

    useEffect(() => {
        if (subscriptionRecordReducerStatus == 200) {
            dispatch(SubscriptionRecordReset());
        } else if (subscriptionRecordReducerStatus == 403) {
            dispatch(logout());
            navigate("/");
        } else if (subscriptionRecordReducerStatus) {
            dispatch(SubscriptionRecordReset());
        }
    }, [subscriptionRecordReducerStatus])

    useEffect(() => {
        if (pauseSubscriptionReducerStatus == 200) {
            dispatch(pauseSubscriptionReset());
            Toast("Subscription Pause Successfully", "success")
            dispatch(SubscriptionRecord({ userId: utilReducers?.userId }));
        } else if (pauseSubscriptionReducerStatus) {
            Toast(pauseSubscriptionReducerMessage ? pauseSubscriptionReducerMessage : "Try again later", "error")
            dispatch(pauseSubscriptionReset());
        }
    }, [pauseSubscriptionReducerStatus])

    useEffect(() => {
        if (unPauseSubscriptionReducerStatus == 200) {
            dispatch(unPauseSubscriptionReset());
            Toast("Subscription Resume Successfully", "success")
            dispatch(SubscriptionRecord({ userId: utilReducers?.userId }));
        } else if (unPauseSubscriptionReducerStatus) {
            Toast(unPauseSubscriptionReducerMessage ? unPauseSubscriptionReducerMessage : "Try again later")
            dispatch(unPauseSubscriptionReset());
        }
    }, [unPauseSubscriptionReducerStatus]);

    useEffect(() => {
        setPauseModel(false);
    }, [handleOutsideClick])


    console.log("subscriptionRecordReducerData :", subscriptionRecordReducerData);


    return (
        <div className="bg-[#000000] h-full">
            <div className={`container-lg bg-[#1E1E1E] px-[4rem] w-full ${isUpgradeSectionEnable ? "" : "mt-[10rem] rounded-[0.8rem]"} flex justify-start items-center`}>
                <h2
                    className={`text-white text-[2.5rem] sm:text-[3rem] font-bold ${isUpgradeSectionEnable ? "cursor-pointer mr-5 hover:text-[#FAFF00]" : ""}`}
                    onClick={() => { setIsUpgradeSectionEnable(false); dispatch(setUpgradeUnable(false)) }}
                >
                    Subscriptions
                </h2>
                <span className="text-white text-[2.5rem] sm:text-[3rem] font-bold">{isUpgradeSectionEnable ? "> Upgrade Package" : ""}</span>
            </div>
            {unPauseSubscriptionReducerLoading || pauseSubscriptionReducerLoading || subscriptionRecordReducerLoading ?
                <div className="flex justify-center items-center">
                    <Oval
                        visible={true}
                        height="50"
                        width="50"
                        color="#FAFF00"
                        ariaLabel="oval-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
                : isUpgradeSectionEnable ?
                    <div className="">
                        <Membership isHeadingNotShow={true} />
                    </div>
                    :
                    <div className="">
                        <div className="text-white text-[2.5rem] font-bold container-lg px-[4rem] py-[1rem]">
                            Active Packages
                        </div>
                        <table class="table-fixed w-full rounded-[0.8rem]">
                            <thead>
                                <tr className=''>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Package Name</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Duration</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Start Date</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Expire</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Status</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Action</th>
                                </tr>
                            </thead>
                            <tbody className='bg-[#1E1E1E] rounded-[0.8rem]'>
                                {subscriptionRecordReducerData.length > 0 ?
                                    subscriptionRecordReducerData?.filter((item) => { return item.status == "Active" })?.map((items) => {
                                        return (
                                            <tr className='border-t-4 border-black'>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>{items?.packageName}</td>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>{items?.totalDurationInDays} Days</td>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>{moment(items?.startDate).format("MMM Do YY")}</td>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>{moment(items?.endDate).format("MMM Do YY")}</td>
                                                <td
                                                    className={`text-[1.6rem] ${items?.status == "Active" ? "text-[#29CC6A] cursor-pointer" : items?.status == "Expired" ? "text-[#FC5555]" : "text-[#FAFF00] cursor-pointer"} py-[2rem] text-center font-[600]`}
                                                    onClick={() => {
                                                        if (items?.status == "Active" && items?.pausedDays != 0) {
                                                            setSubscriptionPauseDays(items?.pausedDays);
                                                            setSelectedSubscriptionId(items?.id);
                                                            setPauseModel(true);
                                                            // dispatch(pauseSubscription({ subscriptionId: items?.id, userId: utilReducers?.userId }))
                                                        } else if (items?.status == "Paused") {
                                                            dispatch(unPauseSubscription({ subscriptionId: items?.id, userId: utilReducers?.userId }))
                                                        } else if (items?.status == "Expired") {
                                                            return
                                                        }
                                                    }
                                                    }

                                                >
                                                    <div
                                                        className=""
                                                        data-tooltip-id="my-tooltip2"
                                                        data-tooltip-content={items?.status == "Active" ? "Pause" : items?.status == "Expired" ? null : "Resume"}
                                                        data-tooltip-place="left"
                                                    >
                                                        {items?.status == "Expired" ?
                                                            null
                                                            :
                                                            <Tooltip id="my-tooltip2" />
                                                        }
                                                        {items?.status}
                                                    </div>
                                                </td>
                                                <td className='py-[2rem] flex justify-center items-center'>
                                                    {
                                                        isUpgradeButtonVisible(items) && items.status != "Expired" ?
                                                            items.packageName == "12MPremium" ?
                                                                <div className="">--</div>
                                                                : items.status != "Expired" ?
                                                                    <button
                                                                        className="bg-[#FAFF00] montserrat-semibold text-center text-[1.5rem] px-[1rem] py-[0.2rem] rounded-[1rem]"
                                                                        onClick={() => { dispatch(setUpgradeUnable(true)); dispatch(subscriptionUpgradeSummary({ subscriptionId: items?.id, userId: utilReducers.userId })); setIsUpgradeSectionEnable(true); }}
                                                                    >
                                                                        Upgrade Package
                                                                    </button>
                                                                    :
                                                                    null
                                                            : isRenewalButtonVisible(items) ?
                                                                items.status != "Expired" ?
                                                                    <button
                                                                        className="bg-[#FAFF00] montserrat-semibold text-center text-[1.5rem] px-[1rem] py-[0.2rem] rounded-[1rem]"
                                                                        onClick={() => { setIsUpgradeSectionEnable(true); dispatch(subscriptionUpgradeSummary({ subscriptionId: items?.id, userId: utilReducers.userId })); dispatch(setUpgradeUnable(false)) }}
                                                                    >
                                                                        Renew Package
                                                                    </button>
                                                                    :
                                                                    null
                                                                :
                                                                null
                                                    }
                                                    {items.packageName == "12MChampion" && isUpgradeButtonVisible(items) == false ?
                                                        items.status != "Expired" ?
                                                            <button
                                                                className="bg-[#FAFF00] montserrat-semibold text-center text-[1.5rem] px-[1rem] py-[0.2rem] rounded-[1rem]"
                                                                onClick={() => { setIsUpgradeSectionEnable(true); dispatch(setUpgradeUnable(true)); dispatch(subscriptionUpgradeSummary({ subscriptionId: items?.id, userId: utilReducers.userId })); }}
                                                            >
                                                                Upgrade Package
                                                            </button>
                                                            :
                                                            null
                                                        :
                                                        null
                                                    }
                                                </td>
                                            </tr>

                                        )
                                    })
                                    :
                                    <tr className='border-t-4 border-black'>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'>No Active Subscription Available</td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                    </tr>


                                }
                            </tbody>
                        </table>
                        <div className="text-white text-[2.5rem] font-bold container-lg px-[4rem] py-[1rem]">
                            In-Active Packages
                        </div>
                        <table class="table-fixed w-full rounded-[0.8rem]">
                            <thead>
                                <tr className=''>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Package Name</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Duration</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Start Date</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Expire</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Status</th>
                                    <th className='text-[1.6rem] text-white py-[1rem]'>Action</th>
                                </tr>
                            </thead>
                            <tbody className='bg-[#1E1E1E] rounded-[0.8rem]'>
                                {!unPauseSubscriptionReducerLoading || !pauseSubscriptionReducerLoading || !subscriptionRecordReducerLoading ? subscriptionRecordReducerData.length > 0 ?
                                    subscriptionRecordReducerData?.filter((item) => { return item.status !== "Active" })?.map((items) => {
                                        return (
                                            <tr className='border-t-4 border-black'>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>{items?.packageName}</td>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>{items?.totalDurationInDays} Days</td>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>{moment(items?.startDate).format("MMM Do YY")}</td>
                                                <td className='text-[1.6rem] text-white py-[2rem] text-center'>{moment(items?.endDate).format("MMM Do YY")}</td>
                                                <td
                                                    className={`text-[1.6rem] ${items?.status == "Active" ? "text-[#29CC6A] cursor-pointer" : items?.status == "Expired" ? "text-[#FC5555]" : "text-[#FAFF00] cursor-pointer"} py-[2rem] text-center font-[600]`}
                                                    onClick={() => {
                                                        if (items?.status == "Active") {
                                                            dispatch(pauseSubscription({
                                                                subscriptionId: items?.id,
                                                                userId: utilReducers?.userId,
                                                                startDate: "",
                                                                endDate: ""
                                                            }))
                                                        } else if (items?.status == "Paused") {
                                                            dispatch(unPauseSubscription({ subscriptionId: items?.id, userId: utilReducers?.userId }))
                                                        } else if (items?.status == "Expired") {
                                                            return
                                                        }
                                                    }
                                                    }

                                                >
                                                    <div
                                                        className=""
                                                        data-tooltip-id="my-tooltip2"
                                                        data-tooltip-content={items?.status == "Active" ? "Pause" : items?.status == "Expired" ? null : "Resume"}
                                                        data-tooltip-place="left"
                                                    >
                                                        {items?.status == "Expired" ?
                                                            null
                                                            :
                                                            <Tooltip id="my-tooltip2" />
                                                        }
                                                        {items?.status}
                                                    </div>
                                                </td>
                                                <td className='py-[2rem] flex justify-center items-center'>
                                                    {
                                                        isUpgradeButtonVisible(items) && items.status != "Expired" ?
                                                            items.packageName == "12MPremium" ?
                                                                <div className="">--</div>
                                                                : items.status != "Expired" ?
                                                                    <button
                                                                        className="bg-[#FAFF00] montserrat-semibold text-center text-[1.5rem] px-[1rem] py-[0.2rem] rounded-[1rem]"
                                                                        onClick={() => { dispatch(setUpgradeUnable(true)); dispatch(subscriptionUpgradeSummary({ subscriptionId: items?.id, userId: utilReducers.userId })); setIsUpgradeSectionEnable(true); }}
                                                                    >
                                                                        Upgrade Package
                                                                    </button>
                                                                    :
                                                                    null
                                                            : isRenewalButtonVisible(items) ?
                                                                items.status != "Expired" ?
                                                                    <button
                                                                        className="bg-[#FAFF00] montserrat-semibold text-center text-[1.5rem] px-[1rem] py-[0.2rem] rounded-[1rem]"
                                                                        onClick={() => { setIsUpgradeSectionEnable(true); dispatch(subscriptionUpgradeSummary({ subscriptionId: items?.id, userId: utilReducers.userId })); dispatch(setUpgradeUnable(false)) }}
                                                                    >
                                                                        Renew Package
                                                                    </button>
                                                                    :
                                                                    null
                                                                :
                                                                null
                                                    }
                                                    {items.packageName == "12MChampion" && isUpgradeButtonVisible(items) == false ?
                                                        items.status != "Expired" ?
                                                            <button
                                                                className="bg-[#FAFF00] montserrat-semibold text-center text-[1.5rem] px-[1rem] py-[0.2rem] rounded-[1rem]"
                                                                onClick={() => { setIsUpgradeSectionEnable(true); dispatch(setUpgradeUnable(true)); dispatch(subscriptionUpgradeSummary({ subscriptionId: items?.id, userId: utilReducers.userId })); }}
                                                            >
                                                                Upgrade Package
                                                            </button>
                                                            :
                                                            null
                                                        :
                                                        null
                                                    }
                                                </td>
                                            </tr>

                                        )
                                    })
                                    :
                                    <tr className='border-t-4 border-black'>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'>No Active Subscription Available</td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                    </tr>
                                    :
                                    <tr className='border-t-4 border-black'>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'>
                                            <Oval
                                                visible={true}
                                                height="25"
                                                width="25"
                                                color="#FAFF00"
                                                ariaLabel="oval-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                            />
                                        </td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                        <td className='text-[1.6rem] text-white py-[2rem] text-center'></td>
                                    </tr>

                                }
                            </tbody>
                        </table>
                    </div>
            }
            <SubscriptionsPauseModel selectedSubscriptionId={selectedSubscriptionId} pauseDays={SubscriptionPauseDays} show={pauseModel} onClose={() => setPauseModel(false)} setPauseModel={setPauseModel} />
        </div>
    );
};

export default Subscriptions;
