import React from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom';

const NavbarMini = ({ show }) => {
    const Navigate = useNavigate();
    const location = useLocation();
    
    const isPrime = location.pathname.includes("/fit-prime");
    const isChamp = location.pathname.includes("/fit-champ");

    return show && (
        <div className='px-[4rem] fixed top-[7.8rem] left-0 right-0 z-[98]'>
            <div className="container-lg bg-[#302F2F] flex justify-start items-start pt-[1rem]">
                <div className="w-[22%]"></div>
                <div className={`text-[2.2rem] cursor-pointer hover:text-[#FAFF00] ${isPrime ? "text-[#FAFF00] urbanist-extrabold" : "text-white urbanist-regular"} mr-[10rem]`} onClick={() => Navigate(`/fit-center/fit-prime`)}>Fit Prime</div>
                <div className={`text-[2.2rem] cursor-pointer hover:text-[#FAFF00] ${isChamp ? "text-[#FAFF00] urbanist-extrabold" : "text-white urbanist-regular"}`} onClick={() => Navigate(`/fit-center/fit-champ`)}>Fit Champ</div>
            </div>
        </div>
    )
}

export default NavbarMini