import React, { useState } from 'react'
import "./VideoPlayer2.css"
import ReactPlayer from 'react-player'
import { VideoPlayer2Img } from '../../../static/constants/imgConstants'
import { useNavigate } from 'react-router-dom'

function VideoPlayer2() {

    const Navigate = useNavigate()

    const [selectedMedia, setSelectedMedia] = useState({
        url1: "https://fit.zone/assets/images/elevate-work/elevate-work-vid.mp4"
    })

    return (
        <div className='h-[100rem] sm:h-[124rem] lg:h-[85rem] px-[4rem] flex flex-col justify-start items-center'>
            <div className="container-lg overflow-hidden">
                <div className="text-white text-[5rem] sm:text-[6.5rem] montserrat-bold text-start xl:text-center my-[4rem]">
                    Elevate your workout game with
                </div>
                <div className="flex flex-col lg:flex-row justify-between items-center overflow-hidden">
                    <div className="w-full xl:w-[50%] flex flex-col justify-between items-start urbanist-medium text-[2.6rem] smtext-[3.5rem] leading-[3rem] sm:leading-[3.6rem] text-white">
                        <div
                            onClick={() => setSelectedMedia({ url1: "https://fit.zone/assets/images/elevate-work/elevate-work-vid.mp4" })}
                            className={`cursor-pointer border-l-[0.55rem] ${selectedMedia?.url1 ? "border-[#F38339]" : "border-white"} px-10 my-6 sm:my-14 overflow-hidden`}
                        >
                            Scientific Workout Online Trainer ADI
                        </div>
                        <div
                            onClick={() => setSelectedMedia({ url2: "https://fit.zone/assets/images/elevate-work/image-2.png" })}
                            className={`cursor-pointer border-l-[0.55rem] ${selectedMedia?.url2 ? "border-[#F38339]" : "border-white"} px-10 my-6 sm:my-14 overflow-hidden`}
                        >
                            Workout Anywhere
                        </div>
                        <div
                            onClick={() => setSelectedMedia({ url3: "https://fit.zone/assets/images/elevate-work/image-3.png" })}
                            className={`cursor-pointer border-l-[0.55rem] ${selectedMedia?.url3 ? "border-[#F38339]" : "border-white"} px-10 my-6 sm:my-14 overflow-hidden`}
                        >
                            Amazing group sessions
                        </div>
                        <div
                            onClick={() => setSelectedMedia({ url4: "https://fit.zone/assets/images/elevate-work/image-4.png" })}
                            className={`cursor-pointer border-l-[0.55rem] ${selectedMedia?.url4 ? "border-[#F38339]" : "border-white"} px-10 my-6 sm:my-14 overflow-hidden`}
                        >
                            State of Art facilities in both category
                        </div>
                        <button className="open-sans-semibold text-[1.6rem] text-black bg-[#FAFF00] p-2 rounded-[2.2rem] px-14 mx-10" onClick={() => Navigate("/fit-center/fit-prime")}>
                            Buy Now
                        </button>
                    </div>
                    <div className="w-full xl:w-[50%] my-10 xl:my-0 rounded-[2.2rem]">
                        {selectedMedia.url1 ?
                            <div className="w-[55rem] sm:w-full h-full rounded-[2.2rem] flex justify-center items-center">
                                <ReactPlayer
                                    url={selectedMedia.url1}
                                    controls={true}
                                    width={"75.1rem"}
                                    light={<img src={VideoPlayer2Img.videoPlayer2CoverImg} alt='Thumbnail' className='w-full h-full rounded-[2.2rem]' />}
                                    playing={true}
                                    height={"38.6rem"}
                                    playbackRate={1}
                                    progressInterval={500}
                                />
                            </div>
                            :
                            <div className="w-[55rem] sm:w-[75.1rem] h-[38.6rem] rounded-[2.2rem]">
                                <img src={selectedMedia.url2 || selectedMedia.url3 || selectedMedia.url4} alt="" className="w-full h-full rounded-[2.2rem]" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VideoPlayer2
