import React from 'react'
import ReactPlayer from 'react-player'
import { base_url_strapi_service } from '../../../store/constants';
import { useNavigate } from 'react-router-dom';

const VideoSpecialCategory = ({ data }) => {

    const navigate = useNavigate();

    return (
        <div className='sm:mx-[20rem] mx-[2rem] flex flex-col lg:flex-row justify-center items-center mt-[10rem]'>
            <div className="w-full flex justify-center items-center lg:block lg:w-[30%] text-white">
                <div className="urbanist-extrabold bordered-font text-transparent text-[3rem] sm:text-[9.4rem] lg:text-[5rem] xl:text-[9.4rem]">
                    {data[0]?.TitlePrimary}
                </div>
                <div className="urbanist-extrabold text-[#ffffff]/50 text-[3rem] sm:text-[9.4rem] lg:text-[5rem] xl:text-[9.4rem]">
                    {data[0]?.TitleSecondary}
                </div>
                <button className="bg-[#FAFF00] text-[#486077] text-[1.7rem] my-[3rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold hidden lg:block" onClick={() => navigate(`/community/blogs/category/blogId`, { state: { data: data } })}>
                    View More
                </button>
            </div>
            <div className="">
                <div className="w-[52rem] sm:w-full h-full rounded-[2.2rem] flex justify-center items-center">
                    <ReactPlayer
                        url={base_url_strapi_service + data[0]?.video?.url}
                        controls={true}
                        width={"75.1rem"}
                        light={<img src={"https://s3-alpha-sig.figma.com/img/8e53/d9e6/a3d5d96230d1ce9ab563c328d251a8b3?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XXKV~pwZsRbDEQTxU9qqIymAeEcDJJhOXr0ddQRRwoaVLcKGSsyx1v1Wi190xXsZj5jSXOOBsl7DIRCSkGnrdfqxf8lebGLK7KN9RtFENMXcxLcQEMIQb0og5QzL8VaWFt9fpav9lVgUJZOnjUJs9s4RGr~efDzZczFd1D9rQ~ev8GHvp~uZ63gzktN-8zLsCBPYkYMsIBEV0mDaLnVlAn0itVQn8EgbmNqCfZ-2DbpqRsWA9FleTfT~C0EI0ONZ6itD-wOj1UH-QJGFocAKcW8cGy-6bg-nwVn3zjOOPK1JCQfHRjma84CPHR3lkbYjlwgw6ncAcJ2GM2SAxdzPdg__"} alt='Thumbnail' className='w-full h-full rounded-[2.2rem]' />}
                        playing={true}
                        height={"38.6rem"}
                        playbackRate={1}
                        progressInterval={500}
                    />
                </div>
            </div>
            <button className="bg-[#FAFF00] text-[#486077] text-[1.7rem] my-[3rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold lg:hidden" onClick={() => navigate(`/community/blogs/category/blogId`, { state: { data: data } })}>
                View More
            </button>
        </div>
    )
}

export default VideoSpecialCategory