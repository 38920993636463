import React from 'react'
import "./WhyChoose.css"

const WhyChoose = () => {
    return (
        <div className='why-choose-section px-[4rem] h-[90rem]'>
            <div className="container-lg flex flex-col justify-start md:justify-center md:items-center my-auto h-full">
                <div className="urbanist-bold text-[8rem] sm:text-[9.4rem] text-white text-start md:text-center">
                    <span className="bordered-font text-transparent">Why</span> Choose Premium?
                </div>
                <div className="text-white text-start md:text-center urbanist-bold text-[4rem]">
                    A community where you can get
                </div>
                <div className="text-white flex flex-col md:flex-row justify-between items-start w-[60%] lg:w-[62%] urbanist-medium text-[3.6rem] mt-[10rem] overflow-hidden">
                    <div className="flex-1 md:pr-[4.5rem] text-start leading-[4.1rem] overflow-visible">
                        Exclusive access to premium gyms
                    </div>
                    <div className="flex-1 md:px-[4.5rem] my-[8.3rem] md:my-0 text-start leading-[4.1rem] overflow-visible">
                        Unlimited group classes
                    </div>
                    <div className="flex-1 md:pl-[4.5rem] text-start leading-[4.1rem] overflow-visible">
                        More options for fitness centres
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChoose