export const actionTypes = {
	USER_LOGOUT: 'USER_LOGOUT',
	USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',

	// Utils
	SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
	SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
	SET_USER_ID: 'SET_USER_ID',
	SET_VERIFIED_PHONE_NO: 'SET_VERIFIED_PHONE_NO',
	SET_SELECTED_PLAN: 'SET_SELECTED_PLAN',
	SET_EMAIL: 'SET_EMAIL',
	SET_JWT_TOKEN: 'SET_JWT_TOKEN',
	SET_PLAN_TYPE: 'SET_PLAN_TYPE',
	SET_NAME: "SET_NAME",
	SET_UPGRADE_UNABLE: "SET_UPGRADE_UNABLE",
	SET_RESEND_OTP_TIME: "SET_RESEND_OTP_TIME",

	// Auth types
	GENERATE_OTP_REQUEST: 'GENERATE_OTP_REQUEST',
	GENERATE_OTP_SUCCESS: 'GENERATE_OTP_SUCCESS',
	GENERATE_OTP_FAILURE: 'GENERATE_OTP_FAILURE',
	GENERATE_OTP_RESET: 'GENERATE_OTP_RESET',
	GENERATE_OTP_RESET_ALL: 'GENERATE_OTP_RESET_ALL',

	VALIDATE_OTP_REQUEST: 'VALIDATE_OTP_REQUEST',
	VALIDATE_OTP_SUCCESS: 'VALIDATE_OTP_SUCCESS',
	VALIDATE_OTP_FAILURE: 'VALIDATE_OTP_FAILURE',
	VALIDATE_OTP_RESET: 'VALIDATE_OTP_RESET',
	VALIDATE_OTP_RESET_ALL: 'VALIDATE_OTP_RESET_ALL',

	REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
	REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
	REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
	REFRESH_TOKEN_RESET: 'REFRESH_TOKEN_RESET',
	REFRESH_TOKEN_RESET_ALL: 'REFRESH_TOKEN_RESET_ALL',

	// Subscription types
	SUBSCRIPTION_REQUEST: 'SUBSCRIPTION_REQUEST',
	SUBSCRIPTION_SUCCESS: 'SUBSCRIPTION_SUCCESS',
	SUBSCRIPTION_FAILURE: 'SUBSCRIPTION_FAILURE',
	SUBSCRIPTION_RESET: 'SUBSCRIPTION_RESET',
	SUBSCRIPTION_RESET_ALL: 'SUBSCRIPTION_RESET_ALL',

	CONTACT_US_REQUEST: 'CONTACT_US_REQUEST',
	CONTACT_US_SUCCESS: 'CONTACT_US_SUCCESS',
	CONTACT_US_FAILURE: 'CONTACT_US_FAILURE',
	CONTACT_US_RESET: 'CONTACT_US_RESET',
	CONTACT_US_RESET_ALL: 'CONTACT_US_RESET_ALL',

	MAKE_PAYMENT_REQUEST: 'MAKE_PAYMENT_REQUEST',
	MAKE_PAYMENT_SUCCESS: 'MAKE_PAYMENT_SUCCESS',
	MAKE_PAYMENT_FAILURE: 'MAKE_PAYMENT_FAILURE',
	MAKE_PAYMENT_RESET: 'MAKE_PAYMENT_RESET',
	MAKE_PAYMENT_RESET_ALL: 'MAKE_PAYMENT_RESET_ALL',

	CHECK_PAYMENT_STATUS_REQUEST: 'CHECK_PAYMENT_STATUS_REQUEST',
	CHECK_PAYMENT_STATUS_SUCCESS: 'CHECK_PAYMENT_STATUS_SUCCESS',
	CHECK_PAYMENT_STATUS_FAILURE: 'CHECK_PAYMENT_STATUS_FAILURE',
	CHECK_PAYMENT_STATUS_RESET: 'CHECK_PAYMENT_STATUS_RESET',
	CHECK_PAYMENT_STATUS_RESET_ALL: 'CHECK_PAYMENT_STATUS_RESET_ALL',

	GEOCODE_REQUEST: 'GEOCODE_REQUEST',
	GEOCODE_SUCCESS: 'GEOCODE_SUCCESS',
	GEOCODE_FAILURE: 'GEOCODE_FAILURE',
	GEOCODE_RESET: 'GEOCODE_RESET',
	GEOCODE_RESET_ALL: 'GEOCODE_RESET_ALL',


	CUSTOMER_PROFILE_REQUEST: 'CUSTOMER_PROFILE_REQUEST',
	CUSTOMER_PROFILE_SUCCESS: 'CUSTOMER_PROFILE_SUCCESS',
	CUSTOMER_PROFILE_FAILURE: 'CUSTOMER_PROFILE_FAILURE',
	CUSTOMER_PROFILE_RESET: 'CUSTOMER_PROFILE_RESET',
	CUSTOMER_PROFILE_RESET_ALL: 'CUSTOMER_PROFILE_RESET_ALL',


	UPLOAD_PROFILE_PIC_REQUEST: 'UPLOAD_PROFILE_PIC_REQUEST',
	UPLOAD_PROFILE_PIC_SUCCESS: 'UPLOAD_PROFILE_PIC_SUCCESS',
	UPLOAD_PROFILE_PIC_FAILURE: 'UPLOAD_PROFILE_PIC_FAILURE',
	UPLOAD_PROFILE_PIC_RESET: 'UPLOAD_PROFILE_PIC_RESET',
	UPLOAD_PROFILE_PIC_RESET_ALL: 'UPLOAD_PROFILE_PIC_RESET_ALL',

	UPDATE_PROFILE_REQUEST: 'UPDATE_PROFILE_REQUEST',
	UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
	UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',
	UPDATE_PROFILE_RESET: 'UPDATE_PROFILE_RESET',
	UPDATE_PROFILE_RESET_ALL: 'UPDATE_PROFILE_RESET_ALL',

	SUBSCRIPTION_RECORD_REQUEST: 'SUBSCRIPTION_RECORD_REQUEST',
	SUBSCRIPTION_RECORD_SUCCESS: 'SUBSCRIPTION_RECORD_SUCCESS',
	SUBSCRIPTION_RECORD_FAILURE: 'SUBSCRIPTION_RECORD_FAILURE',
	SUBSCRIPTION_RECORD_RESET: 'SUBSCRIPTION_RECORD_RESET',
	SUBSCRIPTION_RECORD_RESET_ALL: 'SUBSCRIPTION_RECORD_RESET_ALL',

	CMS_PRODUCTS_REQUEST: 'CMS_PRODUCTS_REQUEST',
	CMS_PRODUCTS_SUCCESS: 'CMS_PRODUCTS_SUCCESS',
	CMS_PRODUCTS_FAILURE: 'CMS_PRODUCTS_FAILURE',
	CMS_PRODUCTS_RESET: 'CMS_PRODUCTS_RESET',
	CMS_PRODUCTS_RESET_ALL: 'CMS_PRODUCTS_RESET_ALL',

	ALL_ORDERS_REQUEST: 'ALL_ORDERS_REQUEST',
	ALL_ORDERS_SUCCESS: 'ALL_ORDERS_SUCCESS',
	ALL_ORDERS_FAILURE: 'ALL_ORDERS_FAILURE',
	ALL_ORDERS_RESET: 'ALL_ORDERS_RESET',
	ALL_ORDERS_RESET_ALL: 'ALL_ORDERS_RESET_ALL',

	CREATE_TICKET_REQUEST: 'CREATE_TICKET_REQUEST',
	CREATE_TICKET_SUCCESS: 'CREATE_TICKET_SUCCESS',
	CREATE_TICKET_FAILURE: 'CREATE_TICKET_FAILURE',
	CREATE_TICKET_RESET: 'CREATE_TICKET_RESET',
	CREATE_TICKET_RESET_ALL: 'CREATE_TICKET_RESET_ALL',

	STRAPI_TOKEN_REQUEST: 'STRAPI_TOKEN_REQUEST',
	STRAPI_TOKEN_SUCCESS: 'STRAPI_TOKEN_SUCCESS',
	STRAPI_TOKEN_FAILURE: 'STRAPI_TOKEN_FAILURE',
	STRAPI_TOKEN_RESET: 'STRAPI_TOKEN_RESET',
	STRAPI_TOKEN_RESET_ALL: 'STRAPI_TOKEN_RESET_ALL',

	//Order Management Service
	EMAIL_INVOICE_REQUEST: 'EMAIL_INVOICE_REQUEST',
	EMAIL_INVOICE_SUCCESS: 'EMAIL_INVOICE_SUCCESS',
	EMAIL_INVOICE_FAILURE: 'EMAIL_INVOICE_FAILURE',
	EMAIL_INVOICE_RESET: 'EMAIL_INVOICE_RESET',
	EMAIL_INVOICE_RESET_ALL: 'EMAIL_INVOICE_RESET_ALL',

	ORDER_CREATE_REQUEST: 'ORDER_CREATE_REQUEST',
	ORDER_CREATE_SUCCESS: 'ORDER_CREATE_SUCCESS',
	ORDER_CREATE_FAILURE: 'ORDER_CREATE_FAILURE',
	ORDER_CREATE_RESET: 'ORDER_CREATE_RESET',
	ORDER_CREATE_RESET_ALL: 'ORDER_CREATE_RESET_ALL',

	ORDER_TRANSACTION_STATUS_REQUEST: 'ORDER_TRANSACTION_STATUS_REQUEST',
	ORDER_TRANSACTION_STATUS_SUCCESS: 'ORDER_TRANSACTION_STATUS_SUCCESS',
	ORDER_TRANSACTION_STATUS_FAILURE: 'ORDER_TRANSACTION_STATUS_FAILURE',
	ORDER_TRANSACTION_STATUS_RESET: 'ORDER_TRANSACTION_STATUS_RESET',
	ORDER_TRANSACTION_STATUS_RESET_ALL: 'ORDER_TRANSACTION_STATUS_RESET_ALL',

	DOWNLOAD_ORDER_INVOICE_PDF_REQUEST: 'DOWNLOAD_ORDER_INVOICE_PDF_REQUEST',
	DOWNLOAD_ORDER_INVOICE_PDF_SUCCESS: 'DOWNLOAD_ORDER_INVOICE_PDF_SUCCESS',
	DOWNLOAD_ORDER_INVOICE_PDF_FAILURE: 'DOWNLOAD_ORDER_INVOICE_PDF_FAILURE',
	DOWNLOAD_ORDER_INVOICE_PDF_RESET: 'DOWNLOAD_ORDER_INVOICE_PDF_RESET',
	DOWNLOAD_ORDER_INVOICE_PDF_RESET_ALL: 'DOWNLOAD_ORDER_INVOICE_PDF_RESET_ALL',

	// SearchByLocation types
	SEARCH_BY_LOCATION_REQUEST: 'SEARCH_BY_LOCATION_REQUEST',
	SEARCH_BY_LOCATION_SUCCESS: 'SEARCH_BY_LOCATION_SUCCESS',
	SEARCH_BY_LOCATION_FAILURE: 'SEARCH_BY_LOCATION_FAILURE',
	SEARCH_BY_LOCATION_RESET: 'SEARCH_BY_LOCATION_RESET',
	SEARCH_BY_LOCATION_RESET_ALL: 'SEARCH_BY_LOCATION_RESET_ALL',

	STRAPI_ARTICLES_REQUEST: 'STRAPI_ARTICLES_REQUEST',
	STRAPI_ARTICLES_SUCCESS: 'STRAPI_ARTICLES_SUCCESS',
	STRAPI_ARTICLES_FAILURE: 'STRAPI_ARTICLES_FAILURE',
	STRAPI_ARTICLES_RESET: 'STRAPI_ARTICLES_RESET',
	STRAPI_ARTICLES_RESET_ALL: 'STRAPI_ARTICLES_RESET_ALL',

	STRAPI_CLIENT_FEEDBACK_REQUEST: 'STRAPI_CLIENT_FEEDBACK_REQUEST',
	STRAPI_CLIENT_FEEDBACK_SUCCESS: 'STRAPI_CLIENT_FEEDBACK_SUCCESS',
	STRAPI_CLIENT_FEEDBACK_FAILURE: 'STRAPI_CLIENT_FEEDBACK_FAILURE',
	STRAPI_CLIENT_FEEDBACK_RESET: 'STRAPI_CLIENT_FEEDBACK_RESET',
	STRAPI_CLIENT_FEEDBACK_RESET_ALL: 'STRAPI_CLIENT_FEEDBACK_RESET_ALL',

	STRAPI_PRESS_AND_MEDIA_REQUEST: 'STRAPI_PRESS_AND_MEDIA_REQUEST',
	STRAPI_PRESS_AND_MEDIA_SUCCESS: 'STRAPI_PRESS_AND_MEDIA_SUCCESS',
	STRAPI_PRESS_AND_MEDIA_FAILURE: 'STRAPI_PRESS_AND_MEDIA_FAILURE',
	STRAPI_PRESS_AND_MEDIA_RESET: 'STRAPI_PRESS_AND_MEDIA_RESET',
	STRAPI_PRESS_AND_MEDIA_RESET_ALL: 'STRAPI_PRESS_AND_MEDIA_RESET_ALL',

	STRAPI_OUR_FIT_TEAM_REQUEST: 'STRAPI_OUR_FIT_TEAM_REQUEST',
	STRAPI_OUR_FIT_TEAM_SUCCESS: 'STRAPI_OUR_FIT_TEAM_SUCCESS',
	STRAPI_OUR_FIT_TEAM_FAILURE: 'STRAPI_OUR_FIT_TEAM_FAILURE',
	STRAPI_OUR_FIT_TEAM_RESET: 'STRAPI_OUR_FIT_TEAM_RESET',
	STRAPI_OUR_FIT_TEAM_RESET_ALL: 'STRAPI_OUR_FIT_TEAM_RESET_ALL',

	STRAPI_FOUNDER_REQUEST: 'STRAPI_FOUNDER_REQUEST',
	STRAPI_FOUNDER_SUCCESS: 'STRAPI_FOUNDER_SUCCESS',
	STRAPI_FOUNDER_FAILURE: 'STRAPI_FOUNDER_FAILURE',
	STRAPI_FOUNDER_RESET: 'STRAPI_FOUNDER_RESET',
	STRAPI_FOUNDER_RESET_ALL: 'STRAPI_FOUNDER_RESET_ALL',

	GET_FITNESS_ASSESSMENT_REQUEST: 'GET_FITNESS_ASSESSMENT_REQUEST',
	GET_FITNESS_ASSESSMENT_SUCCESS: 'GET_FITNESS_ASSESSMENT_SUCCESS',
	GET_FITNESS_ASSESSMENT_FAILURE: 'GET_FITNESS_ASSESSMENT_FAILURE',
	GET_FITNESS_ASSESSMENT_RESET: 'GET_FITNESS_ASSESSMENT_RESET',
	GET_FITNESS_ASSESSMENT_RESET_ALL: 'GET_FITNESS_ASSESSMENT_RESET_ALL',

	GET_TICKET_BY_USER_ID_REQUEST: 'GET_TICKET_BY_USER_ID_REQUEST',
	GET_TICKET_BY_USER_ID_SUCCESS: 'GET_TICKET_BY_USER_ID_SUCCESS',
	GET_TICKET_BY_USER_ID_FAILURE: 'GET_TICKET_BY_USER_ID_FAILURE',
	GET_TICKET_BY_USER_ID_RESET: 'GET_TICKET_BY_USER_ID_RESET',
	GET_TICKET_BY_USER_ID_RESET_ALL: 'GET_TICKET_BY_USER_ID_RESET_ALL',

	PAUSE_SUBSCRIPTION_REQUEST: 'PAUSE_SUBSCRIPTION_REQUEST',
	PAUSE_SUBSCRIPTION_SUCCESS: 'PAUSE_SUBSCRIPTION_SUCCESS',
	PAUSE_SUBSCRIPTION_FAILURE: 'PAUSE_SUBSCRIPTION_FAILURE',
	PAUSE_SUBSCRIPTION_RESET: 'PAUSE_SUBSCRIPTION_RESET',
	PAUSE_SUBSCRIPTION_RESET_ALL: 'PAUSE_SUBSCRIPTION_RESET_ALL',

	UN_PAUSE_SUBSCRIPTION_REQUEST: 'UN_PAUSE_SUBSCRIPTION_REQUEST',
	UN_PAUSE_SUBSCRIPTION_SUCCESS: 'UN_PAUSE_SUBSCRIPTION_SUCCESS',
	UN_PAUSE_SUBSCRIPTION_FAILURE: 'UN_PAUSE_SUBSCRIPTION_FAILURE',
	UN_PAUSE_SUBSCRIPTION_RESET: 'UN_PAUSE_SUBSCRIPTION_RESET',
	UN_PAUSE_SUBSCRIPTION_RESET_ALL: 'UN_PAUSE_SUBSCRIPTION_RESET_ALL',

	SUBSCRIPTION_UPGRADE_SUMMARY_REQUEST: 'SUBSCRIPTION_UPGRADE_SUMMARY_REQUEST',
	SUBSCRIPTION_UPGRADE_SUMMARY_SUCCESS: 'SUBSCRIPTION_UPGRADE_SUMMARY_SUCCESS',
	SUBSCRIPTION_UPGRADE_SUMMARY_FAILURE: 'SUBSCRIPTION_UPGRADE_SUMMARY_FAILURE',
	SUBSCRIPTION_UPGRADE_SUMMARY_RESET: 'SUBSCRIPTION_UPGRADE_SUMMARY_RESET',
	SUBSCRIPTION_UPGRADE_SUMMARY_RESET_ALL: 'SUBSCRIPTION_UPGRADE_SUMMARY_RESET_ALL',

	VENUE_STAFF_REQUEST: 'VENUE_STAFF_REQUEST',
	VENUE_STAFF_SUCCESS: 'VENUE_STAFF_SUCCESS',
	VENUE_STAFF_FAILURE: 'VENUE_STAFF_FAILURE',
	VENUE_STAFF_RESET: 'VENUE_STAFF_RESET',
	VENUE_STAFF_RESET_ALL: 'VENUE_STAFF_RESET_ALL',

	STRAPI_FAQS_REQUEST: 'STRAPI_FAQS_REQUEST',
	STRAPI_FAQS_SUCCESS: 'STRAPI_FAQS_SUCCESS',
	STRAPI_FAQS_FAILURE: 'STRAPI_FAQS_FAILURE',
	STRAPI_FAQS_RESET: 'STRAPI_FAQS_RESET',
	STRAPI_FAQS_RESET_ALL: 'STRAPI_FAQS_RESET_ALL',

	GX_SESSION_REQUEST: 'GX_SESSION_REQUEST',
	GX_SESSION_SUCCESS: 'GX_SESSION_SUCCESS',
	GX_SESSION_FAILURE: 'GX_SESSION_FAILURE',
	GX_SESSION_RESET: 'GX_SESSION_RESET',
	GX_SESSION_RESET_ALL: 'GX_SESSION_RESET_ALL',

	GET_REFERRAL_CODE_REQUEST: 'GET_REFERRAL_CODE_REQUEST',
	GET_REFERRAL_CODE_SUCCESS: 'GET_REFERRAL_CODE_SUCCESS',
	GET_REFERRAL_CODE_FAILURE: 'GET_REFERRAL_CODE_FAILURE',
	GET_REFERRAL_CODE_RESET: 'GET_REFERRAL_CODE_RESET',
	GET_REFERRAL_CODE_RESET_ALL: 'GET_REFERRAL_CODE_RESET_ALL',

	REFER_FRIEND_REQUEST: 'REFER_FRIEND_REQUEST',
	REFER_FRIEND_SUCCESS: 'REFER_FRIEND_SUCCESS',
	REFER_FRIEND_FAILURE: 'REFER_FRIEND_FAILURE',
	REFER_FRIEND_RESET: 'REFER_FRIEND_RESET',
	REFER_FRIEND_RESET_ALL: 'REFER_FRIEND_RESET_ALL',

	FITCOIN_HISTORY_REQUEST: 'FITCOIN_HISTORY_REQUEST',
	FITCOIN_HISTORY_SUCCESS: 'FITCOIN_HISTORY_SUCCESS',
	FITCOIN_HISTORY_FAILURE: 'FITCOIN_HISTORY_FAILURE',
	FITCOIN_HISTORY_RESET: 'FITCOIN_HISTORY_RESET',
	FITCOIN_HISTORY_RESET_ALL: 'FITCOIN_HISTORY_RESET_ALL',

	SUBSCRIBE_REQUEST: 'SUBSCRIBE_REQUEST',
	SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SUCCESS',
	SUBSCRIBE_FAILURE: 'SUBSCRIBE_FAILURE',
	SUBSCRIBE_RESET: 'SUBSCRIBE_RESET',
	SUBSCRIBE_RESET_ALL: 'SUBSCRIBE_RESET_ALL',

	GET_LEADER_BOARD_FILE_REQUEST: 'GET_LEADER_BOARD_FILE_REQUEST',
	GET_LEADER_BOARD_FILE_SUCCESS: 'GET_LEADER_BOARD_FILE_SUCCESS',
	GET_LEADER_BOARD_FILE_FAILURE: 'GET_LEADER_BOARD_FILE_FAILURE',
	GET_LEADER_BOARD_FILE_RESET: 'GET_LEADER_BOARD_FILE_RESET',
	GET_LEADER_BOARD_FILE_RESET_ALL: 'GET_LEADER_BOARD_FILE_RESET_ALL',

	TRAINER_RATING_REQUEST: 'TRAINER_RATING_REQUEST',
	TRAINER_RATING_SUCCESS: 'TRAINER_RATING_SUCCESS',
	TRAINER_RATING_FAILURE: 'TRAINER_RATING_FAILURE',
	TRAINER_RATING_RESET: 'TRAINER_RATING_RESET',
	TRAINER_RATING_RESET_ALL: 'TRAINER_RATING_RESET_ALL',

	COMMUNITY_REQUEST: 'COMMUNITY_REQUEST',
	COMMUNITY_SUCCESS: 'COMMUNITY_SUCCESS',
	COMMUNITY_FAILURE: 'COMMUNITY_FAILURE',
	COMMUNITY_RESET: 'COMMUNITY_RESET',
	COMMUNITY_RESET_ALL: 'COMMUNITY_RESET_ALL',

	COMMUNITY_SUB_CATEGORIES_REQUEST: 'COMMUNITY_SUB_CATEGORIES_REQUEST',
	COMMUNITY_SUB_CATEGORIES_SUCCESS: 'COMMUNITY_SUB_CATEGORIES_SUCCESS',
	COMMUNITY_SUB_CATEGORIES_FAILURE: 'COMMUNITY_SUB_CATEGORIES_FAILURE',
	COMMUNITY_SUB_CATEGORIES_RESET: 'COMMUNITY_SUB_CATEGORIES_RESET',
	COMMUNITY_SUB_CATEGORIES_RESET_ALL: 'COMMUNITY_SUB_CATEGORIES_RESET_ALL',

	GET_VENUE_DETAILS_REQUEST: 'GET_VENUE_DETAILS_REQUEST',
	GET_VENUE_DETAILS_SUCCESS: 'GET_VENUE_DETAILS_SUCCESS',
	GET_VENUE_DETAILS_FAILURE: 'GET_VENUE_DETAILS_FAILURE',
	GET_VENUE_DETAILS_RESET: 'GET_VENUE_DETAILS_RESET',
	GET_VENUE_DETAILS_RESET_ALL: 'GET_VENUE_DETAILS_RESET_ALL',

	OFFER_SEARCH_REQUEST: 'OFFER_SEARCH_REQUEST',
	OFFER_SEARCH_SUCCESS: 'OFFER_SEARCH_SUCCESS',
	OFFER_SEARCH_FAILURE: 'OFFER_SEARCH_FAILURE',
	OFFER_SEARCH_RESET: 'OFFER_SEARCH_RESET',
	OFFER_SEARCH_RESET_ALL: 'OFFER_SEARCH_RESET_ALL',

	SUBSCRIPTION_OFFERS_REQUEST: 'SUBSCRIPTION_OFFERS_REQUEST',
	SUBSCRIPTION_OFFERS_SUCCESS: 'SUBSCRIPTION_OFFERS_SUCCESS',
	SUBSCRIPTION_OFFERS_FAILURE: 'SUBSCRIPTION_OFFERS_FAILURE',
	SUBSCRIPTION_OFFERS_RESET: 'SUBSCRIPTION_OFFERS_RESET',
	SUBSCRIPTION_OFFERS_RESET_ALL: 'SUBSCRIPTION_OFFERS_RESET_ALL',

}

