import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../../hooks/useToast";
import { Oval } from "react-loader-spinner";
import {
    contactUs,
    contactUsReset,
} from "../../../store/actions/leadgenServiceActions/contactUsActions";
import { searchByLocation } from "../../../store/actions/venueManagementServiceActions/searchByLocationActions";
import MapComponent from "../../utils/MapComponent/MapComponent";
import FloatingInput from "../FloatingInput/FloatingInput";

function AboutUsGridSec4() {
    const dispatch = useDispatch();
    const Toast = useToast();

    const contactUsReducersStatus = useSelector(
        (state) => state.contactUsReducers.status
    );
    const contactUsReducersMessage = useSelector(
        (state) => state.contactUsReducers.message
    );
    const contactUsReducersLoading = useSelector(
        (state) => state.contactUsReducers.loading
    );
    const searchByLocationReducersData = useSelector(
        (state) => state.searchByLocationReducers.data
    );

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mob, setMob] = useState("");
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phonePattern = /^\d{10}$/;

    const handleContactUs = () => {
        if (name === "") {
            Toast("Please enter name");
        } else if (!name.split(" ")[1]) {
            Toast("Please enter last Name also");
        } else if (!emailPattern.test(email)) {
            Toast("Please enter a valid email");
        } else if (!phonePattern.test(mob)) {
            Toast("Please enter a valid Phone number");
        } else {
            let splitName = name.split(" ");
            dispatch(
                contactUs({
                    city: "",
                    description: "",
                    email: email,
                    firstName: splitName[0],
                    lastName: splitName[1],
                    mobileNumber: {
                        countryCode: "91",
                        number: mob,
                    },
                    subject: "string",
                    source: "string",
                    leadType: "string",
                })
            );
        }
    };

    useEffect(() => {
        dispatch(searchByLocation());
    }, []);

    useEffect(() => {
        if (contactUsReducersStatus === 200) {
            Toast("Form Submitted Successfully!");
            dispatch(contactUsReset());
        } else if (contactUsReducersStatus) {
            Toast(contactUsReducersMessage ? contactUsReducersMessage : "Form not Submit! Please try again later");
            dispatch(contactUsReset());
        }
    }, [contactUsReducersStatus]);

    return (
        <div className="about-us-grid-sec4 bg-[#000] min-h-max lg:h-[48rem] my-[4rem] relative">
            <div className="lg:absolute w-full lg:w-[64%] h-full top-0 left-0 bg-white">
                <div className="flex items-center justify-center w-full">
                    <MapComponent
                        searchByLocationReducersData={
                            searchByLocationReducersData
                        }
                    />
                </div>
            </div>
            <div className="container-lg flex h-full xl:mr-[5rem] mt-[3rem] md:mt-0">
                <div className="hidden lg:flex items-center justify-center w-[66%]" />
                <div className="flex flex-col items-start justify-center lg:px-[7rem] xl:px-[8rem] text-white w-full lg:w-[38%]">
                    <h1 className="text-[4rem] font-bold text-[#D0D0D0] mb-2 urbanist-bold overflow-hidden">
                        Drop your details, and
                        We will reach out
                    </h1>
                    <p className="w-[30rem] text-[1.7rem] text-[#666666] mb-2 urbanist-medium overflow-hidden">
                        See yourself the positive outcomes of partnering with our
                        gym chain, reinforcing our commitment to your success.
                    </p>
                    <FloatingInput
                        label="Name"
                        id="Name"
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        isReq
                    />
                    <FloatingInput
                        label="Email"
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        isReq
                    />
                    <FloatingInput
                        label="Phone Number"
                        id="phone"
                        type="number"
                        value={mob}
                        onChange={(e) => setMob(e.target.value)}
                        isReq
                    />
                    <button
                        className="mt-4 bg-[#FAFF00] rounded-full font-semibold text-[1.7rem] text-[#486077] px-[3.9rem] py-[1.4rem] overflow-hidden"
                        onClick={() => handleContactUs()}
                    >
                        {contactUsReducersLoading ? (
                            <div className="flex justify-center items-center w-full">
                                <Oval
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#000000"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                />
                            </div>
                        ) : (
                            "Submit"
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default AboutUsGridSec4;
