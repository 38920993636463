import React from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { containerStyle, defaultCenter, mapStyles } from './mapStyles';
import './MapComponent.css'

function MapComponent({ geoLocation, venueName, searchByLocationReducersData }) {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyCSxcC8KD4LqOHxk6baZjaUx9Xqlwgqh_U' // TODO get the key from fitzones developer account
    });

    return isLoaded ? (
        <section className="map-component w-full flex justify-center">
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={defaultCenter}
                zoom={11}
                // options={{
                //     styles: mapStyles
                // }}
            >
                {!geoLocation ?
                    searchByLocationReducersData?.venues?.filter((item) => item.geolocation).map((items) => {
                        return (
                            <Marker
                                position={{ lat: Number(items.geolocation.latitude), lng: Number(items.geolocation.longitude) }}
                                // icon={<HiMapPin />}
                                label={{
                                    text: items.name,
                                    color: 'blue',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    labelClass: "marker",
                                    labelInBackground: false
                                }}
                            />
                        )
                    })
                    :
                    <Marker
                        position={{ lat: Number(geoLocation?.latitude), lng: Number(geoLocation?.longitude) }}
                        // icon={<HiMapPin />}
                        label={{
                            text: venueName,
                            color: 'blue',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            labelClass: "marker",
                            labelInBackground: false
                        }}
                    />
                }
            </GoogleMap>
        </section>
    ) : <></>
}

export default MapComponent;
