import React, { useEffect, useState } from 'react'
import MembershipPackagesCard from './MembershipPackagesCard'
import "./MembershipPackages.css"
import "swiper/css";
import 'swiper/element/css/pagination';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { subscription, subscriptionReset } from '../../../store/actions/SubscriptionManagementActions/subscriptionActions';
import { register } from 'swiper/element/bundle';

const MembershipPackages = () => {
    register();
    const [screenSize] = useState(window.innerWidth);
    const { package_type } = useParams();
    const dispatch = useDispatch();

    const utilReducers = useSelector((state) => state.utilReducers);
    const subscriptionReducersData = useSelector((state) => state.subscriptionReducers?.data);
    const subscriptionReducersStatus = useSelector((state) => state.subscriptionReducers?.status);

    useEffect(() => {
        dispatch(subscription());
    }, [])

    useEffect(() => {
        if (subscriptionReducersStatus == 200) {
            dispatch(subscriptionReset());
        } else if (subscriptionReducersStatus) {
            dispatch(subscriptionReset());
        }
    }, [subscriptionReducersStatus])

    return (
        <div id='packages' className='px-[4rem] membership-packages select-none'>
            <div className="container-lg">
                <div className="montserrat-bold text-[6.5rem] text-white text-center mt-[1rem]">
                    {package_type === "fit-prime" ? "Explore Premium" : "Explore Champion"}
                </div>
                <div className="urbanist-medium text-[3.2rem] text-white text-center">
                    {package_type === "fit-prime" ? "Unlock More, Pay Less. Where Value Meets Fitness" : "Maximize savings, minimize spendings"}
                </div>
                <div className="text-center urbanist-bold text-[4.8rem] text-white mb-3">
                    Our List Packages
                </div>
                <div className="mb-[1rem]">
                    {package_type === "fit-prime" ?
                        screenSize > 1024 ?
                            <div className="flex justify-center items-center gap-[2.4rem]">
                                {subscriptionReducersData?.subscriptions?.filter((item) => item.subscriptionCategoryName == "Premium")?.map((items, index) => {
                                    if (index > 3) {
                                        return null
                                    } else {
                                        console.log("items :", items);
                                        return (
                                            <div className="">
                                                <MembershipPackagesCard
                                                    items={items}
                                                    package_type={package_type}
                                                    planDuration={items.totalDuration?.toFixed()}
                                                    price={items.totalAmount / items.totalDuration}
                                                    billedAmount={items.totalAmount}
                                                    points={items.description}
                                                    id={items.id}
                                                />
                                            </div>
                                        )
                                    }
                                })
                                }
                            </div>
                            :
                            <div className="">
                                <swiper-container pagination="true" part="pagination">
                                    {subscriptionReducersData?.subscriptions?.filter((item) => item.subscriptionCategoryName == "Premium")?.map((items, index) => {
                                        if (index > 3) {
                                            return null
                                        } else {
                                            console.log("items :", items);
                                            return (
                                                <swiper-slide>
                                                    <div className="flex justify-center items-center">
                                                        <MembershipPackagesCard
                                                            items={items}
                                                            package_type={package_type}
                                                            planDuration={items.totalDuration?.toFixed()}
                                                            price={items.totalAmount / items.totalDuration}
                                                            billedAmount={items.totalAmount}
                                                            points={items.description}
                                                            id={items.id}
                                                        />
                                                    </div>
                                                </swiper-slide>
                                            )
                                        }
                                    })
                                    }
                                    <div className="swiper-pagination"></div>
                                </swiper-container>
                            </div>
                        :
                        screenSize > 1024 ?
                            <div className="flex justify-center items-center gap-[2.4rem]">
                                {subscriptionReducersData?.subscriptions?.filter((item) => item.subscriptionCategoryName == "Champion")?.map((items, index) => {
                                    if (index > 3) {
                                        return null
                                    } else {
                                        return (
                                            <div className="">
                                                <MembershipPackagesCard
                                                    items={items}
                                                    package_type={package_type}
                                                    planDuration={items.totalDuration?.toFixed()}
                                                    price={items.totalAmount / items.totalDuration}
                                                    billedAmount={items.totalAmount}
                                                    points={items.description}
                                                    id={items.id}
                                                />
                                            </div>
                                        )
                                    }
                                })
                                }
                            </div>
                            :
                            <div className="">
                                <swiper-container pagination="true" part="pagination">
                                    {subscriptionReducersData?.subscriptions?.filter((item) => item.subscriptionCategoryName == "Champion")?.map((items, index) => {
                                        if (index > 3) {
                                            return null
                                        } else {
                                            return (
                                                <swiper-slide>
                                                    <div className="flex justify-center items-center">
                                                        <MembershipPackagesCard
                                                            items={items}
                                                            package_type={package_type}
                                                            planDuration={items.totalDuration?.toFixed()}
                                                            price={items.totalAmount / items.totalDuration}
                                                            billedAmount={items.totalAmount}
                                                            points={items.description}
                                                            id={items.id}
                                                        />
                                                    </div>
                                                </swiper-slide>
                                            )
                                        }
                                    })
                                    }
                                </swiper-container>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default MembershipPackages