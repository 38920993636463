import React from 'react'
import { FaCircleCheck } from 'react-icons/fa6';
import { IoCheckmark } from "react-icons/io5";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedPlan } from '../../../store/actions/utilsActions/utilsActions';

const Membership2Card = ({ type, planDuration, points, planValue, id, items }) => {

    const Navigate = useNavigate()
    const dispatch = useDispatch();

    const parsePoints = (htmlString) => {
        // Create a temporary container to parse the HTML string
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        // Extract paragraphs and return an array of text content
        const paragraphs = Array.from(tempDiv.getElementsByTagName('p')).map(p => p.innerHTML);
        // console.log("htmlString :", htmlString);
        // console.log("paragraphs :", paragraphs);
        return paragraphs;
    }

    // Get the parsed points as an array of strings
    const parsedPoints = parsePoints(points);
    return (
        <div className='border border-[#757575] rounded-[2.5rem] px-[2rem] relative overflow-visible my-[6.7rem] h-[75rem] [@media(max-width:1280px)]:h-[80rem] md:h-[72rem] xl:my-0 w-[42.3rem] lg:mx-[2.7rem]'>
            <div className={`absolute -top-14 left-[20%] right-[20%] ${type === "CHAMPION" ? "bg-[#05A6C2]" : "bg-[#F38339]"} px-[2.5rem] py-[1.5rem] urbanist-medium text-[2.4rem] text-white rounded-[0.5rem] z-10 flex justify-center items-center`}>
                {type}
            </div>
            <div className="urbanist-semibold text-[4.8rem] text-white mt-[8.3rem] text-center">
                {planDuration}
            </div>
            <div className="my-[3rem] urbanist-medium text-[1.6rem]">
                {parsedPoints.map((point, index) => (
                    <div key={index} className={`flex items-center mb-4 ${planDuration === 3 ? "" : "text-white"}`}>
                        <IoCheckmark className='mr-[1rem]' size={28} color={`${planDuration === 3 ? "#000" : "#ffff"}`} />
                        <span dangerouslySetInnerHTML={{ __html: point }} />
                    </div>
                ))}
            </div>
            <div className="text-center urbanist-semibold text-[4.8rem] text-[#FBF5FF]">
                ₹{planValue?.toFixed()}/month
            </div>
            <button
                className="bg-[#FAFF00] py-[1.7rem] w-full urbanist-semibold text-[2.6rem] rounded-[1.3rem] my-[2.3rem]"
                onClick={() => {
                    dispatch(setSelectedPlan(items));
                    Navigate(`/fit-center/${type === "CHAMPION" ? "fit-champ" : "fit-prime"}/${id}`);
                }}
            >
                Buy Now
            </button>
        </div>
    )
}

export default Membership2Card