// Navbar imgs
export const NavbarImg = {
    LogoLight: require("../img/logo.png"),
    LogoDark: require("../img/logo-dark.svg"),
    Exercise: require("../img/exercise.png"),
    logoSmall: require("../img/logo-small.png")
};


export const OurStoryPageImg = {
    OurStoryLandingImg1: require("../img/person.png"),
    FitTeamImg1: require('../../static/img/arvind.png'),
    FitTeamImg2: require('../../static/img/vijay.png'),
    FitTeamImg3: require('../../static/img/rajgopal.png'),
    FitTeamImg4: require('../../static/img/venugopal.png'),
    TimesLogo: require("../../static/img/times-logo.png"),
    HTLogo: require("../../static/img/ht-logo.png"),
    NDTVLogo: require("../../static/img/ndtv-logo.png"),
    MAPLogo: require("../../static/img/map-logo.png"),
    FooterLogo: require("../../static/img/footer-logo.png"),
    // FacebookLogo: require("../../static/img/facebook-logo.png"),
    // InstaLogo: require("../../static/img/insta-logo.png"),
    // LinkedinLogo: require("../../static/img/linkedin-logo.png"),
    // TwitterLogo: require("../../static/img/twitter-logo.png"),
};


export const NearbyGymCardImg = {
    cardImg1: require("../img/near-by-gym-card-img1.jpg"),
    cardImg2: require("../img/near-by-gym-card-img2.jpg"),
    cardImg3: require("../img/near-by-gym-card-img3.jpg"),
    premium: require("../img/premium.png"),
    champion: require("../img/champion.png"),
    students: require("../img/students.png"),

}

export const VideoPlayer1Img = {
    cardVideoImg1: require("../img/video-player-card-img1.jpg"),
    cardVideoImg2: require("../img/video-player-card-img2.jpg"),
    cardVideoImg3: require("../img/video-player-card-img3.jpg"),
    cardVideoImg4: require("../img/video-player-card-img4.jpg"),
    VideoPlayer1CoverImg: require("../img/video-player1-coverpage.jpg"),
    VideoPlayer1CoverSmallImg: require("../img/video-player1-coverpage-small.jpg"),
}

export const HomeFitnessImg = {
    fitnessImg1: require("../img/fitness-img-1.jpg"),
    fitnessImg2: require("../img/fitness-img-2.jpg"),
    fitnessImg3: require("../img/fitness-img-3.jpg"),
    fitnessImg4: require("../img/fitness-img-4.jpg"),
    fitnessImg5: require("../img/fitness-img-5.jpg")
}

export const DownloadAppImg = {
    googleIcon: require("../img/google-play-icon.png"),
    appleIcon: require("../img/apple-app-store-icon.png"),
    downloadApp: require("../img/download-app-img.jpg"),
    downloadApp2: require("../images/download-app-img.png"),
}

export const ClientFeeBackImg = {
    clientImg1: require("../img/client-img-1.png"),
    clientImg2: require("../img/client-img-2.png"),
    clientImg3: require("../img/client-img-3.png"),
}

export const BlogAndNewsImg = {
    blogAndNewsCardImg: require("../img/blog-and-news-card-img.png"),
}

export const AboutUsImg = {
    AboutUsImg1Logo: require('../../static/img/about-us-landing-img.jpg'),
    AboutUsImg1: require('../../static/img/AboutUsImg1.png'),
    AboutUsImg2: require('../../static/img/AboutUsImg2.png'),
    AboutUsImg3: require('../../static/img/AboutUsImg3.png'),
    AboutUsImg4: require('../../static/img/AboutUsImg4.png'),
    AboutUsImg5: require('../../static/img/AboutUsImg5.png'),
    AboutUsImg6: require('../../static/img/AboutUsImg6.png'),
    AboutUsImg7: require('../../static/img/AboutUsImg7.png'),
    AboutUsImg8: require('../../static/img/AboutUsImg8.png'),
    AboutUsImg9: require('../../static/img/AboutUsImg9.png'),
    AboutUsImg10: require('../../static/img/AboutUsImg10.png'),
    AboutUsImg11: require('../../static/img/AboutUsImg11.png'),
    AboutUsImg12: require('../../static/img/AboutUsImg12.png'),
    AboutUsImg13: require('../../static/img/AboutUsImg13.png'),
    AboutUsImg14: require('../../static/img/AboutUsImg14.png'),
    AboutUsImg15: require('../../static/img/AboutUsImg15.png'),
    AboutUsImg16: require('../../static/img/partnerImage2.jpg'),
};

export const HomeLandingCarouselImg = {
    workoutProgramImg: require("../img/workout-program-bg.png")
}

export const VideoPlayer2Img = {
    videoPlayer2CoverImg: require("../img/video-player2-coverimg.jpg")
}

export const VideoPlayer3Img = {
    videoPlayer3CoverImg: require("../img/video-player3-cover-img.jpg")
}

export const VideoPlayer4Img = {
    videoPlayer4CoverImg: require("../img/video-player4-cover-img.png"),
    progressBar: require("../img/progress-bar.png"),
    ratingReview: require("../img/rating-review.png")
}

export const FitCentersLandingImg = {
    fitCentersCard1Img: require("../images/fit-centers-card1-img.png")
}

export const PackageImgAndDetailsImg = {
    PackageDetailsImg: require("../img/home-landing-crousal-item3-bg.jpg")
}

export const DownloadAppLandingImg = {
    PhoneImg1: require("../../static/img/phoneimg1.png"),
    googleIcon: require("../img/google-play-icon.png"),
    appleIcon: require("../img/apple-app-store-icon.png"),
    bannerImage: require("../img/bannerImage.png"),
    downloadAppLandingMobileImg: require("../img/download-app-landing-mobile-img.jpg"),
    AppBanner2: require("../img/AppBanner2.png"),
    AppBanner3: require("../img/AppBanner3.png"),
    DownloadApp2: require("../img/DownloadApp2.png"),
}


export const FounderImg = {
    FounderImg1: require("../img/founder-img.png"),
    PartnerLogo: require("../img/PartnerLogo.png"),
    FounderCardIcon: require("../img/icons/founder-card-icon.png"),
}

export const GymPageImg = {
    gymViewImg: require("../img/gym-view-img.jpg")
}

export const GymTraitsIconSectionImg = {
    gymtraitsIcon1: require("../img/gymtraits-icon1.png"),
    gymtraitsIcon2: require("../img/gymtraits-icon2.png"),
    gymtraitsIcon3: require("../img/gymtraits-icon3.png"),
    gymtraitsIcon4: require("../img/gymtraits-icon4.png"),
    gymtraitsIcon5: require("../img/gymtraits-icon5.png"),
    gymtraitsIcon6: require("../img/gymtraits-icon6.png"),
}

export const ExploreOtherGymImg = {
    exploreOtherGymImg1: require("../img/explore-other-gym-card-img1.png"),
    exploreOtherGymImg2: require("../img/explore-other-gym-card-img2.png"),
    exploreOtherGymImg3: require("../img/explore-other-gym-card-img3.png"),
}

export const Membership2Img = {
    membership2UserImg: require("../img/membership2-user-img.png")
}

export const FlagImg = {
    flag: require("../img/flag.png")
}


export const CommunityImg = {
    communityimg1: require("../img/communityimg1.png"),
    card1: require("../img/card1.png"),
    card2: require("../img/card2.png"),
    card3: require("../img/card3.png"),
    card4: require("../img/card4.png"),
    checkout1: require("../img/checkout1.png"),
    checkout2: require("../img/checkout2.png"),
    checkout3: require("../img/checkout3.png"),
    checkout4: require("../img/checkout4.png"),
    diaries: require("../img/diaries.png"),
    music: require("../img/music.png"),

}