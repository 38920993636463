import React from 'react'
import { register } from 'swiper/element/bundle';
import { FitCentersLandingImg } from '../../../static/constants/imgConstants';
import HomeLandingCrousalItem1 from '../../main/HomeLandingCarousel/HomeLandingCarouselItem1';
import HomeLandingCrousalItem3 from '../../main/HomeLandingCarousel/HomeLandingCarouselItem3';
import "swiper/css";
import 'swiper/element/css/pagination';
import "./FitCentersLanding.css"

const FitCentersLanding = () => {
    register();

    return (
        <div className=''>
            <swiper-container pagination="true" keyboard="true">
                <swiper-slide>
                    <div className="fit-centers-landing px-[4rem]">
                        <div className="container-lg flex justify-between items-end h-full">
                            <div className="flex justify-start md:justify-between items-end relative">
                                <div className="absolute md:relative bottom-[20%] sm:bottom-[10%] md:bottom-0 text-[9.6rem] text-white urbanist-semibold sm:mb-[22%] leading-[10rem] w-full md:w-[60%] overflow-hidden z-[11]">
                                    <span className="text-[#FFF200]">Choose</span> Your Path <br /> to Greatness
                                </div>
                                <div className='mx-auto fit-centers-landing-img md:mb-0 z-[9]'>
                                    <img className='w-full h-full z-[9]' src={"https://s3-alpha-sig.figma.com/img/ec50/702b/1491500f4ade037f0215535b6dcb3486?Expires=1731888000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=KSxxjkQNIimLbdzql7wu~ypvv-qTAYOU3B-oTs8nvKRT~Q9vUi-W9uBRGTALEZcai2k5A1Dm9UvCoFnvsOWaqzm77rRSnw6feFl2lcd~nddCJM8IlHSwYo-yQE-KAv-jfUdU-atv2HO7iR1v0XAPLXGcppUaCz25zotAFVLPKtuzOSzkD-KaVKV6ijqf-67nUG6TIfBi-k6~56rM-67MdLYGk-IPeNO~2lz05Jy~HiMPaPmevlKyLnI14xv6HLWI-OdqzcL1wBetqL8vSfL6jmbDhfRbR6ACV37IzDsvkeYd9n~jfbGcuH94u-nXA5mTrS4Ny0ugyVdw~AfG7fkgNQ__"} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <HomeLandingCrousalItem1 />
                </swiper-slide>
                <swiper-slide>
                    <HomeLandingCrousalItem3 />
                </swiper-slide>
            </swiper-container>
        </div>
    )
}

export default FitCentersLanding