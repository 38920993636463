import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { base_url_strapi_service } from '../../store/constants';
import Markdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import ExploreCategories from '../../components/common/ExploreCategories/ExploreCategories';
import Footer3 from '../../components/common/Footer3/Footer3';
import { community } from '../../store/actions/StrapiServiceActions/communityAction';
import { Oval } from 'react-loader-spinner';
import Workout from '../../components/common/Workout/Workout';

const CategoryBlogs = () => {

    const location = useLocation();

    const dispatch = useDispatch()

    const data = location.state?.data || {}

    const [isFullyLoaded, setIsFullyLoaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        setIsLoading(true); // Show loading spinner
        setTimeout(() => {
            setIsFullyLoaded(!isFullyLoaded); // Toggle fully loaded state
            setIsLoading(false); // Hide spinner after 1 second
        }, 1000); // 1 second delay
    };

    const communityReducerData = useSelector((state) => state.communityReducer?.data);
    const communitySubCategoriesReducerData = useSelector((state) => state.communitySubCategoriesReducer?.data);

    useEffect(() => {
        dispatch(community());
    }, [])

    console.log("CategoryBlogs communityReducerData :", communityReducerData);

    return (
        <div id='blog-details' className='bg-[#000000] md:bg-[#1E1E1E]'>
            <div className='container-lg px-[4rem]'>
                <div className='w-full'>
                    <div className='w-full flex flex-col justify-center items-start md:flex-row mt-[11rem]'>
                        <div className='w-full flex md:flex-col justify-center text-center lg:w-[34%] text-[5rem] md:text-[9.4rem] mt-[13rem]'>
                            <h1 className="bordered-font text-transparent overflow-hidden">{data?.TitlePrimary}</h1>
                            <h1 className="text-[#FAFF00] mx-[0rem] md:mx-[6rem] overflow-hidden">{data?.TitleSecondary}</h1>
                        </div>
                        <div className='md:w-[62%]'>
                            <div className='w-full'>
                                <div className='w-full h-auto '>
                                    <img src={base_url_strapi_service + data?.image?.url} alt="Wishlist" className="w-[96rem] h-[60rem] object-cover" />
                                </div>
                                <div className="text-[6rem] text-white urbanist-semibold">
                                    {data?.description}
                                </div>
                                <div
                                    className={`w-full overflow-hidden transition-all duration-1000 ease-in-out`}
                                    style={{ maxHeight: isFullyLoaded ? "10000px" : "312px" }}
                                >
                                    <Markdown className="text-white text-[2rem] my-5" children={data?.content} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`w-full ${isFullyLoaded ? "hidden" : " flex"} justify-center my-[4rem]`}>
                <button
                    className='bg-[#FAFF00] text-[#486077] text-[1.7rem] h-[5rem] px-[4rem] rounded-[5rem] urbanist-semibold'
                    onClick={handleClick}
                    disabled={isLoading} // Disable button while loading
                >
                    {isLoading ? (
                        <div className="flex justify-center items-center w-full">
                            <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#000000"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div>
                    ) : (
                        "Load More"
                    )}
                </button>
            </div>
            <div className="py-[4rem]">
                <Workout
                    cards={communityReducerData?.length > 0 ? communityReducerData?.filter((item) => { return item.sub_category.slug == data.sub_category.slug && item.slug != data.slug }) : []}
                    isMarginTrue={true}
                    title1={"More Featured posts from " + data?.sub_category?.sub_category_name}
                />
            </div>
            <div className="py-[4rem]">
                <Workout
                    cards={communityReducerData?.filter((item, index) => { return index > 5 && index < 10 })}
                    isMarginTrue={true}
                    title1={"Explore trending articles"}
                />
            </div>
            <div className='py-[4rem]'>
                <ExploreCategories data={communitySubCategoriesReducerData} selectedCategory={data?.sub_category?.slug} />
            </div>
            <Footer3 />
        </div>
    )
}

export default CategoryBlogs
