import moment from 'moment';
import React from 'react'
import Tilt from 'react-parallax-tilt';
import { useNavigate } from 'react-router-dom';
import { base_url_strapi_service } from '../../../store/constants';

function FeaturedArticlesCard({ member, isBlogPage }) {
    const navigate = useNavigate()

    const scrollToDetails = () => {
        // Update the URL based on the title
        navigate(`/community/blogs/${member?.sub_category?.slug}#blog-details`, { state: { data: member } })

        const packagesSection = document.getElementById('blog-details');
        if (packagesSection) {
            packagesSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    console.log("FeaturedArticlesCard Data :", member);

    return (
        <div className="w-full fit-team-card rounded-[1rem] justify-center text-center cursor-pointer hover:scale-95 transition ease-in-out relative" onClick={() => scrollToDetails()}>
            <Tilt className="background-stripes parallax-effect p-[1rem]" perspective={900}>
                <img
                    src={base_url_strapi_service + member?.image?.url}
                    alt={member?.description}
                    className="w-[33rem] h-[37rem] object-cover rounded-[1rem]"
                />
            </Tilt>
            <h3 className={`text-[1.4rem] ${isBlogPage ? "my-[2rem]" : "my-[4rem]"} text-left urbanist-bold md:text-[2.4rem] px-[1rem]`}>{member.description}</h3>
            <div className="text-[1.8rem] text-white flex justify-between items-center urbanist-semibold px-[1rem]">
                <span>{moment(member?.category?.createdAt).format("DD-MM-YYYY")}</span>
                <span>{"3 min read"}</span>
            </div>
            {member?.label ?
                <div className={`absolute top-0 left-10 py-[0.5rem] px-[1.2rem] rounded-[0.5rem] ${member?.label == "NEW" ? "bg-[#0099FF]" : member?.label == "TRENDING" ? "bg-[#0FAD4E]" : "bg-[#FF082C]"}`}>
                    {member?.label}
                </div>
                :
                null
            }
        </div>
    )
}

export default FeaturedArticlesCard