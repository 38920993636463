import React from "react";
import { AboutUsImg } from "../../../static/constants/imgConstants";

function AboutUsGridSec5() {
    return (
        <div className="about-us-grid-sec5 bg-[#000] min-h-max lg:h-[55rem] mt-[2rem]">
            <div className="container-lg flex h-full px-[4rem] w-full">
                <div className="w-full lg:w-[30%] flex flex-col justify-center">
                    <div className="text-[5.5rem] urbanist-bold leading-[6rem] overflow-hidden">
                        Powered by
                        Our Incredible
                        Partners
                    </div>
                    <p className="text-[1.7rem] mt-8 urbanist-medium xl:w-[50%]">
                        At Fit.Zone, our success is fueled by an exceptional network of partners.
                        Their support and expertise enhance our services and help us deliver
                        outstanding fitness experiences. We’re grateful for their invaluable
                        contributions and proud to work together to create a healthier, stronger
                        community.
                    </p>
                </div>
            </div>
            <div className="md:hidden bg-black h-[50%] w-full mt-[10rem]">
                <img src={AboutUsImg.AboutUsImg16} alt="" className="w-full h-full" />
            </div>
        </div>
    );
}

export default AboutUsGridSec5;
