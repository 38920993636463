import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '../../../hooks/useToast';
import OrderInvoiceCard from '../OrderInvoiceCard/OrderInvoiceCard';
import { Oval } from 'react-loader-spinner';
import { allOrders, allOrdersReset, allOrdersResetAll } from '../../../store/actions/orderManagementActions/allOrdersAction';

const OrderInvoice = () => {

  const dispatch = useDispatch();
  const Toast = useToast()

  const utilReducers = useSelector((state) => state.utilReducers);
  const allOrdersReducerData = useSelector((state) => state.allOrdersReducer.data);
  const allOrdersReducerStatus = useSelector((state) => state.allOrdersReducer.status);
  const allOrdersReducerLoading = useSelector((state) => state.allOrdersReducer.loading);
  const allOrdersReducerMessage = useSelector((state) => state.allOrdersReducer.message);

  useEffect(() => {
    dispatch(allOrdersResetAll());
    dispatch(allOrders({ userId: utilReducers?.userId }))
  }, []);

  useEffect(() => {
    if (allOrdersReducerStatus == 200) {
      dispatch(allOrdersReset());
    } else if (allOrdersReducerStatus) {
      Toast(allOrdersReducerMessage ? allOrdersReducerMessage : "No Orders available this time! Please try again later");
      dispatch(allOrdersReset());
    }
  }, [allOrdersReducerStatus])


  return (
    <div className='bg-[#1E1E1E] no-scrollbar'>
      <div className="container-lg px-[4rem] flex flex-col justify-start items-start">
        <div className="w-full bg-black text-[#ffffff] mt-[10rem] rounded-[2rem]">
          <h1 className="text-[2rem] md:text-[2.5rem] text-start px-[4rem] p-4">All Orders</h1>
        </div>
        <div className="w-full">
          {!allOrdersReducerLoading ?
            allOrdersReducerData?.orders?.length > 0 ?
              allOrdersReducerData?.orders?.map((items, index) => {
                return (
                  <OrderInvoiceCard data={items} index={index} />
                )
              })
              :
              <div className="text-[2rem] text-white flex justify-center items-center w-full my-[5rem]">No Orders Found</div>
            :
            <div className="flex justify-center items-center w-full my-[5rem]">
              <Oval
                visible={true}
                height="50"
                width="50"
                color="#FAFF00"
                ariaLabel="oval-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default OrderInvoice