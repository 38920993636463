import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DayPicker } from 'react-day-picker';
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { useToast } from '../../../hooks/useToast';
import { format } from 'date-fns';
import { refreshToken, refreshTokenReset } from '../../../store/actions/authActions/refreshTokenActions';
import EnterMobilePopup from '../EnterMobilePopup/EnterMobilePopup';
import EnterOtpPopup from '../EnterOtpPopup/EnterOtpPopup';
import "react-day-picker/dist/style.css";
import "./PackageStartDate.css"
import FAQSectionItem from '../FAQSection/FAQSectionItem';
import { Oval } from 'react-loader-spinner';
import { Strapifaqs } from '../../../store/actions/StrapiServiceActions/StrapifaqsAction';

const PackageStartDate = ({ offerDuration }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Toast = useToast();
    const { package_type, package_id } = useParams();

    const utilReducers = useSelector((state) => state.utilReducers);
    const refreshTokenReducerStatus = useSelector((state) => state.refreshTokenReducer.status);
    const refreshTokenReducerLoading = useSelector((state) => state.refreshTokenReducer.loading);
    const strapifaqsReducerData = useSelector((state) => state.strapifaqsReducer.data);
    const validateOtpReducerLoading = useSelector((state) => state.validateOtpReducer.loading);

    const [selected, setSelected] = useState();
    const [showCalendar, setShowCalendar] = useState(false);
    const [showMobPopup, setShowMobPopup] = useState(false);
    const [showOTPPopup, setShowOTPPopup] = useState(false);

    const [handleOutsideClick, setHandleOutsideClick] = useState(false)

    const today = new Date();
    const isPastDate = date => date < new Date(today.setHours(0, 0, 0, 0) - 1);

    useEffect(() => {
        if (refreshTokenReducerStatus === 200) {
            dispatch(refreshTokenReset());
            navigate(`/fit-center/${package_type}/${package_id}/init-pay`)
        } else if (refreshTokenReducerStatus) {
            Toast("Session expired! 😓")
            setShowMobPopup(true);
        }
    }, [refreshTokenReducerStatus]);

    useEffect(() => {
        if (selected) {
            setShowCalendar(false);
        } else {
            setShowCalendar(true);
        }
    }, [selected]);

    useEffect(() => {
        setShowMobPopup(false);
        setShowOTPPopup(false);
    }, [handleOutsideClick])

    useEffect(() => {
        dispatch(Strapifaqs({ category: "my-account" }));
    }, [])


    const groupBySubCategory = (strapifaqsReducerData) => {
        return strapifaqsReducerData.length > 0 ? strapifaqsReducerData?.reduce((acc, item) => {
            if (!acc[item?.SubCategory]) {
                acc[item?.SubCategory] = [];
            }
            acc[item?.SubCategory].push(item);
            return acc;
        }, {})
            :
            null;
    };

    const [groupedData, setGroupedData] = useState(null);

    useEffect(() => {
        if (strapifaqsReducerData && strapifaqsReducerData.length > 0) {
            const grouped = groupBySubCategory(strapifaqsReducerData);
            setGroupedData(grouped);
        } else {
            setGroupedData(null); // Handle empty or undefined data
        }
    }, [strapifaqsReducerData]);

    console.log("utilReducers :", utilReducers?.email);



    return (
        <div className='w-full lg:w-[50%]'>
            <div className="text-white urbanist-semibold text-[3.6rem]">
                {offerDuration?.toFixed()} {offerDuration?.toFixed() == "1" ? "Month" : "Months"} Offer!
            </div>
            <div className="text-white urbanist-semibold text-[2rem] my-[1.5rem]">
                <div className="">
                    Unlimited Classes Anytime, Anywhere with Fit Zone
                </div>
                <div className="">
                    Unlock {offerDuration?.toFixed()} {offerDuration?.toFixed() == "1" ? "Month" : "Months"} of Unlimited Access!
                </div>
                {/* <div className="">
                    45 Days of membership pause T&C
                </div> */}
            </div>
            <div className="mb-[3rem] w-full">
                <label for="date" className='text-white urbanist-semibold text-[1.4rem] mx-5'>Starts On</label>
                <div className="flex flex-col lg:flex-row justify-start items-start my-[1rem] w-full sm:w-[80%] md:w-[90%] 2xl:w-[75%]">
                    <div className="w-[50rem] sm:w-[45rem] md:w-[40rem] lg:w-[36rem] mr-5 z-[5]">
                        <div
                            onClick={() => setShowCalendar(!showCalendar)}
                            className="bg-[#000000]/40 text-white w-full p-4 rounded-[2.5rem] urbanist-semibold text-[1.4rem] outline-none border border-white flex justify-between items-center"
                        >
                            <div className="mx-2">
                                {selected ? format(selected, 'PP') : "Pick Start Date"}
                            </div>
                            {showCalendar ?
                                <GoChevronUp size={26} className='mx-3' />
                                :
                                <GoChevronDown size={26} className='mx-3' />
                            }
                        </div>
                        {showCalendar ?
                            <DayPicker
                                mode="single"
                                selected={selected}
                                onSelect={setSelected}
                                disabled={isPastDate}
                                className='bg-black text-white urbanist-medium text-[1.6rem] rounded-[1.3rem] date-picker px-2'
                                modifiersStyles={{
                                    selected: {
                                        backgroundColor: "white",
                                        color: "black",
                                    }
                                }}
                            />
                            :
                            null
                        }
                    </div>
                    <button
                        className={`rounded-[2.5rem] bg-[#FAFF00] p-4 py-[1.35rem] ${utilReducers?.phoneNumber?.number?.length == 10 && utilReducers?.phoneNumber?.isVerified ? 'px-[3.5rem]' : 'px-[4.2rem]'} urbanist-semibold text-[1.6rem] mt-[2rem] lg:mt-0`}
                        onClick={() => {
                            if (selected) {
                                if ((utilReducers?.phoneNumber?.number?.length == 10
                                    ||
                                    utilReducers?.email)
                                &&
                                utilReducers?.accessToken?.length > 0) {
                                    dispatch(refreshToken({
                                        refreshToken: utilReducers?.refreshToken,
                                        accessToken: utilReducers?.accessToken
                                    }))
                                } else {
                                    setHandleOutsideClick(false)
                                    setTimeout(() =>{
                                        setShowMobPopup(true);
                                    }, 50)
                                }
                            } else {
                                Toast("Please Pick a start Date first");
                            }
                        }}
                    >
                        {refreshTokenReducerLoading || validateOtpReducerLoading ?
                            <div className="flex justify-center items-center w-full">
                                <Oval
                                    visible={true}
                                    height="25"
                                    width="25"
                                    color="#000000"
                                    ariaLabel="oval-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="w-full"
                                />
                            </div>
                            :
                            (utilReducers?.phoneNumber?.number?.length == 10
                                ||
                                utilReducers?.email)
                                &&
                                utilReducers?.accessToken?.length > 0 ?
                                "Buy Now" : "Verify Mobile"
                        }
                    </button>
                </div>
            </div>
            <div className="urbanist-semibold text-[2rem]">
                <div className="">
                    <div className="text-white">
                        Place Holder
                    </div>
                    <div className="text-[#ffffff]/50">
                        <div className="">
                            Join Fit Zone and enjoy unlimited classes at any center in your city for a full year! Our diverse group workouts, led by qualified fitness experts, cater to all levels—from beginners to seasoned pros.
                        </div>
                        <div className="">
                            Key Features: Book Your Workout: Choose from a variety of classes that fit your fitness goals.
                        </div>
                        <div className="">
                            Flexible Gym Access: Check in via your phone and start your workout whenever you like.
                            Live Online Workouts: Join a wide range of live workouts from anywhere.
                            Embrace your fitness journey with Fit Zone and achieve your goals on your terms!
                        </div>
                    </div>
                </div>
                <div className="my-[2.6rem]">
                    <div className="text-white">
                        FAQ’s
                    </div>
                    <div className="text-[#4d4d4d] w-full sm:w-[60%]">
                        {
                            groupedData ?
                                Object.keys(groupedData)?.length > 0 ? (
                                    Object.keys(groupedData)?.map((subCategory, index) => (
                                        <div key={subCategory} className=''>
                                            {/* <div className="bg-[#4F4F4F] rounded-[1.8rem]">
                                            <div className="my-[3.6rem] mx-[3.2rem] text-white dm-sans-bold text-[2.4rem]">
                                                {index + 1}. {subCategory}
                                            </div>
                                        </div> */}
                                            {/* <div className="bg-[#21211F] md:mt-[4.4rem] mb-[9.6rem] flex flex-col justify-center items-center px-[4rem] sm:px-[10rem] py-[6rem] rounded-b-[2rem] md:rounded-[2rem]"> */}
                                            {groupedData[subCategory].length <= 3 ? groupedData[subCategory]?.map((item, idx) => {
                                                if (idx <= 3) {
                                                    return (
                                                        <FAQSectionItem faqSectionData={item} isPackageDetailsPageActive={true} index={index} />
                                                    )
                                                }
                                            })
                                                :
                                                null
                                            }
                                            {/* </div> */}
                                        </div>
                                    ))
                                ) : (
                                    <div className="text-white dm-sans-bold text-[2.4rem] text-center">
                                        No data Available at this time! Please try again later...
                                    </div>
                                )
                                :
                                <div className="text-white dm-sans-bold text-[2.4rem] text-center">
                                    Loading data...
                                </div>
                        }
                    </div>
                </div>
            </div>
            <EnterMobilePopup
                show={showMobPopup}
                setHandleOutsideClick={setHandleOutsideClick}
                onClose={() => {
                    setShowMobPopup(false);
                    setHandleOutsideClick(false);
                    setTimeout(() =>{
                        setShowOTPPopup(true)
                    }, 50)
                }}
            />
            <EnterOtpPopup
                setHandleOutsideClick={setHandleOutsideClick}
                show={showOTPPopup}
                onClose={() => setShowOTPPopup(false)}
                resendOtp={() => {
                    setShowOTPPopup(false);
                    setTimeout(() =>{
                        setShowMobPopup(true);
                    }, 50)
                }}
            />
        </div>
    )
}

export default PackageStartDate
